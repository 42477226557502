import { Icon, Popover } from '@successmode-ktp/kui';
import IMAGE_CHART_NUMBER_1 from '@/assets/refund/img_chart_number_1.png';
import IMAGE_CHART_NUMBER_2 from '@/assets/refund/img_chart_number_2.png';
import IMAGE_CHART_NUMBER_3 from '@/assets/refund/img_chart_number_3.png';
import IMAGE_CHART_NUMBER_4 from '@/assets/refund/img_chart_number_4.png';
import IMAGE_CHART_NUMBER_5 from '@/assets/refund/img_chart_number_5.png';
import IMAGE_CHART_NUMBER_6 from '@/assets/refund/img_chart_number_6.png';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function MedicalChartNumberModal({ isOpen, onClose }: Props) {
  const stepList = [
    {
      id: 1,
      title: '병원정보 > 고객 차트번호 추가 ON',
      image: IMAGE_CHART_NUMBER_1,
      description: (
        <>
          <strong>기능 활성화/비활성화 설정은 언제든 변경</strong>할 수
          있습니다.
          <br />
          기능이 활성화되는 즉시 고객 차트번호 추가 기능을 사용할 수 있습니다.
        </>
      ),
    },
    {
      id: 2,
      title: '외국인 관광객 정보 입력 후 여권확인 버튼 클릭',
      image: IMAGE_CHART_NUMBER_2,
    },
    {
      id: 3,
      title: '고객 차트번호 입력',
      image: IMAGE_CHART_NUMBER_3,
      description: (
        <>
          병원에서 관리하는 <span>고객의 차트번호를 숫자, 문자</span>로 자유롭게
          입력 가능합니다.
        </>
      ),
    },
    {
      id: 4,
      title: '입력된 고객 차트번호 확인 후 공급내역 입력',
      image: IMAGE_CHART_NUMBER_4,
    },
    // {
    //   id: 5,
    //   title: '환급내역 > 고객 차트번호 확인',
    //   image: IMAGE_CHART_NUMBER_5,
    // },
  ];
  return (
    <Popover
      hasBackdrop
      isOpen={isOpen}
      style={{
        padding: '60px 48px',
        borderRadius: '20px',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
      onClickBackdrop={onClose}
    >
      <Header>
        <Title>고객 차트번호 추가 기능 사용방법</Title>
        <CloseButton onClick={onClose}>
          <Icon.X size={36} fillColor='#cccccc' />
        </CloseButton>
      </Header>
      <SubTitle>환급하기 절차</SubTitle>
      <StepList>
        {stepList.map((stepItem) => (
          <li key={stepItem.id}>
            <StepTitle>
              {stepItem.id}. {stepItem.title}
            </StepTitle>
            {stepItem.description && (
              <StepDescription>{stepItem.description}</StepDescription>
            )}
            <StepImage src={stepItem.image} alt='' />
          </li>
        ))}
      </StepList>
      <SubTitle>환급 내역 확인</SubTitle>
      <StepList>
        <li>
          <StepTitle>환급내역 &gt; 고객 차트번호 확인</StepTitle>
          <StepImage src={IMAGE_CHART_NUMBER_5} alt='' />
        </li>
        <li>
          <StepTitle>
            환급내역 &gt; 조회정보 필터로 원하는 정보만 노출
          </StepTitle>
          <StepImage src={IMAGE_CHART_NUMBER_6} alt='' />
        </li>
      </StepList>
    </Popover>
  );
}

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
`;

const Title = styled.h4`
  color: #246cf6;
  font-size: 36px;
  font-weight: 700;
`;

const CloseButton = styled.button``;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #246cf6;
  font-size: 36px;
  font-weight: 700;
  background-color: #f4f8ff;
  height: 102px;
  margin-bottom: 60px;
`;

const StepList = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 80px;
  width: 1048px;
`;

const StepImage = styled.img`
  width: 100%;
  margin-top: 24px;
`;

const StepTitle = styled.h4`
  color: #111;
  font-size: 24px;
  font-weight: 700;
`;

const StepDescription = styled.p`
  margin-top: 8px;
  color: #777777;
  font-size: 16px;

  strong {
    color: #111;
    font-weight: 700;
  }

  span {
    font-weight: 700;
  }
`;

export default MedicalChartNumberModal;
