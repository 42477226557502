import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import {
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
} from '@/util/format';
import Typography from '../common/Typography';
import SelectBox from './SupplyDetail/SelectBox';
import FlexWrap from '../common/FlexWrap';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { ProductTypeList } from '@/constants/supply';
import { kioskRefundModalState } from '@/store/modules/validate';
import { DIRECT_PRICE } from '@/constants/common';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import { Button as ButtonComponent } from '@successmode-ktp/kui';
import ChartNumberModal from './ChartNumberModal';

function KioskStoreRefundModal({
  onRefund,
  setLeafletModalInfo,
  isOpenedChartModal,
  onOpenChartModal,
}: any) {
  const [isActive, setIsActive] = useState(false);
  const [isOver600Modal, setIsOver600Modal] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const {
    register,
    setFocus,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const dispatch = useAppDispatch();
  const { temporaryStorage } = useAppSelector((state) => state.refund);
  const { globalStatus } = useGetGlobalStatus();

  useEffect(() => {
    if (!isOpenedChartModal) {
      setTimeout(() => {
        // setFocus('totalPrice');
        setValue('totalPrice', '');
        setValue('paymentCard', '');
        setValue('paymentCash', '');
      }, 200);
    }
  }, [isOpenedChartModal]);

  const onChangeTotalPrice = (e: any) => {
    const value =
      e.target.value.charAt(0) === '0'
        ? e.target.value.substring(1).replace(/[^0-9]/g, '')
        : e.target.value.replace(/[^0-9]/g, '');

    setValue('totalPrice', getComma(value));
    setValue('paymentCard', getComma(value));

    setIsActive(value !== '');

    if (
      isOver600Modal &&
      getValues('totalPrice').replace(/(,)/g, '') > DIRECT_PRICE
    ) {
      return;
    }

    if (getValues('totalPrice').replace(/(,)/g, '') > DIRECT_PRICE) {
      setIsOver600Modal(true);
      setLeafletModalInfo({
        active: true,
        isOver600: true,
        isKioskError: false,
      });
    } else {
      setIsOver600Modal(false);
    }
  };

  const onChangeCashPrice = (e: any) => {
    // 금액이 0으로 시작하는거 방지
    const value = e.target.value.replace(/^0+/, '').replace(/[^0-9]/g, '');

    let totalValue = parseFloat(getValues('totalPrice').replace(/,/g, '') || 0);
    const cashValue = Math.min(value, totalValue);
    const cardValue = totalValue - cashValue;

    setValue('paymentCash', getComma(Math.min(totalValue, +value)));
    setValue('paymentCard', getComma(cardValue));
  };

  // 임시저장 값 불러오기
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      setValue('paymentCash', temporaryStorage.paymentCash);
      setValue('paymentCard', temporaryStorage.paymentCard);
      setValue('totalPrice', temporaryStorage.totalPrice);
    }
  }, [temporaryStorage]);

  // 모달 닫앗을때 입력값 첫번째 공급내역에 입력값 할당
  const onClickClose = () => {
    const currTotalPrice = getValues('totalPrice');
    let currProductType = 15;

    ProductTypeList.forEach((item) => {
      if (selectValue === item.value) {
        currProductType = item.key;
      }
    });
    setValue(`supply.${0}.price`, getComma(currTotalPrice));
    setValue(
      `supply.${0}.supplyPrice`,
      getComma(getSupplyPaymentPrice(currTotalPrice.replace(/(,)/g, ''))),
    );
    setValue(
      `supply.${0}.vatPrice`,
      getComma(
        getVatPaymentPrice(
          currTotalPrice.replace(/(,)/g, ''),
          getSupplyPaymentPrice(currTotalPrice.replace(/(,)/g, '')).toString(),
        ),
      ),
    );
    setValue(`supply.${0}.productType`, currProductType);

    dispatch(kioskRefundModalState(false));
  };

  return (
    <>
      <BackDrop>
        <Container onSubmit={onRefund}>
          <FlexWrap dir='column' alignItems='center'>
            <Typography
              size='21px'
              lineHeight='31.5px'
              fontWeight='700'
              fontFamily='Pretendard'
              color='#3A3B3E'
            >
              환급을 진행하시겠습니까?
            </Typography>
            <Typography size='14px' color='#80848A' margin='8px 0 0 0'>
              임시저장을 하시려면 [닫기]를 누른 후 페이지 맨 아래 [임시저장]
              버튼을 눌러주세요.
            </Typography>
          </FlexWrap>
          {globalStatus?.isChartNumberEnabled && (
            <ChartNumberInfo>
              <ChartNumberDiv>
                <ChartNumberLabel>고객명</ChartNumberLabel>
                <ChartNumberData>{getValues('touristName')}</ChartNumberData>
              </ChartNumberDiv>
              <ChartNumberDiv>
                <ChartNumberLabel>차트번호</ChartNumberLabel>
                <ChartNumberData>
                  {getValues('chartNumber')}
                  <button
                    type='button'
                    style={{
                      display: 'flex',
                      padding: '4px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      backgroundColor: '#ddd',
                      borderRadius: '4px',
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      onOpenChartModal();
                    }}
                  >
                    변경
                  </button>
                </ChartNumberData>
              </ChartNumberDiv>
            </ChartNumberInfo>
          )}
          <DivideLine />
          <InputItems>
            <InputItem isSelectBox>
              <Item isSelectBox>
                <Typography
                  size='16px'
                  fontWeight='600'
                  lineHeight='24px'
                  color='#3A3B3E'
                  margin='0 8px 0 0'
                >
                  시술 항목
                </Typography>
                <SelectBox
                  name='productType'
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                />
              </Item>
              <Item>
                <Typography
                  size='16px'
                  lineHeight='24px'
                  fontWeight='600'
                  margin='0 24px 0 0'
                  color='#3A3B3E'
                >
                  시술 금액
                </Typography>
                <InputContainer isSelectBox>
                  <Input
                    {...register(`totalPrice`, {})}
                    onChange={(e) => onChangeTotalPrice(e)}
                    placeholder='시술 금액 입력'
                    autoComplete='off'
                    isSelectBox
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        dispatch(kioskRefundModalState(false));
                      }
                    }}
                  />
                  <Typography
                    fontWeight='500'
                    color='#5F6165'
                    margin='0 0 0 8px'
                  >
                    원
                  </Typography>
                </InputContainer>
              </Item>
            </InputItem>

            <InputItem margin='24px 0 16px 0'>
              <Item>
                <Typography
                  fontWeight='600'
                  margin='0 24px 0 0'
                  color='#5F6165'
                >
                  카드 결제
                </Typography>
                <InputContainer>
                  <Input
                    {...register(`paymentCard`)}
                    autoComplete='off'
                    readOnly
                  />
                  <Typography
                    fontWeight='500'
                    color='#80848A'
                    margin='0 0 0 8px'
                  >
                    원
                  </Typography>
                </InputContainer>
              </Item>
            </InputItem>

            <InputItem>
              <Item>
                <Typography
                  fontWeight='600'
                  margin='0 24px 0 0'
                  color='#5F6165'
                >
                  현금 결제
                </Typography>
                <InputContainer isCash>
                  <Input
                    {...register(`paymentCash`)}
                    autoComplete='off'
                    placeholder='현금 결제 금액 입력'
                    isCash
                    onChange={onChangeCashPrice}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        dispatch(kioskRefundModalState(false));
                      }
                    }}
                  />
                  <Typography
                    fontWeight='500'
                    color='#5F6165'
                    margin='0 0 0 8px'
                  >
                    원
                  </Typography>
                </InputContainer>
                {errors.totalPrice && (
                  <ErrorMessage>
                    {String(errors.totalPrice.message)}
                  </ErrorMessage>
                )}
              </Item>
            </InputItem>
          </InputItems>
          <DivideLine />
          <FlexWrap gap='16px' margin='0 0 0 auto'>
            <CloseButton onClick={onClickClose}>닫기</CloseButton>
            <Button disabled={!isActive}>환급하기</Button>
          </FlexWrap>
        </Container>
      </BackDrop>
    </>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;
const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 880px;
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a7a7a7;
  margin: 32px 0;
`;
const InputItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
const InputItem = styled.div<{ isSelectBox?: boolean; margin?: string }>`
  display: flex;
  justify-content: ${(props) =>
    props.isSelectBox ? 'space-between' : 'flex-end'};
  margin: ${(props) => props.margin || '0'};
  ${(props) =>
    props.isSelectBox &&
    css`
      flex: 1;
    `}
`;
const Item = styled.div<{ isSelectBox?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isSelectBox &&
    css`
      flex: 1;
    `}
`;
const InputContainer = styled.div<{ isSelectBox?: boolean; isCash?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
  height: 44px;
  padding: 11px;
  border-radius: 4px;
  background-color: #f5f6f7;
  ${(props) =>
    props.isSelectBox &&
    css`
      border: 1.5px solid #246cf6;
      background-color: #f4f8ff;
    `}
  ${(props) =>
    props.isCash &&
    css`
      border: 1.5px solid #e5e6e8;
      background-color: #fff;
    `}
`;
const Input = styled.input<{ isSelectBox?: boolean; isCash?: boolean }>`
  height: 24px;
  text-align: right;
  outline: none;
  border: none;
  background-color: #f5f6f7;
  ${(props) =>
    props.isSelectBox &&
    css`
      background-color: #f4f8ff;
      ::placeholder {
        text-align: right;
        color: #030303;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    `}
  ${(props) =>
    props.isCash &&
    css`
      border: none;
      background-color: #fff;
    `}
`;
const Button = styled.button<{ isClose?: boolean }>`
  width: 335px;
  padding: 14px 0;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #246cf6;
  flex: 1.5;
  :disabled {
    background-color: #d3e2fd;
  }
  ${(props) =>
    props.isClose &&
    css`
      flex: 1;
      color: #3a3b3e;
      background-color: #e5e6e8;
    `}
`;
const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  width: 335px;
  padding: 14px 0;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #246cf6;
  flex: 1.5;
  flex: 1;
  color: #3a3b3e;
  background-color: #e5e6e8;
  cursor: pointer;
`;
const ErrorMessage = styled.p`
  position: absolute;
  bottom: -28px;
  right: 0;
  font-size: 12px;
  color: ${(props) => props.theme.priamryColors.error};
`;

const ChartNumberInfo = styled.dl`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  margin-top: 32px;
`;
const ChartNumberDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4f4;
  border-radius: 8px;
  width: 100%;
  height: 54px;
  padding: 0 16px;
`;
const ChartNumberLabel = styled.dt`
  color: #777777;
  font-size: 16px;
  font-weight: 600;
`;
const ChartNumberData = styled.dd`
  display: flex;
  align-items: center;
  gap: 16px;
  color: #111;
  font-size: 14px;
  font-weight: 600;
`;
export default KioskStoreRefundModal;
