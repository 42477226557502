import React, { useState } from 'react';
import ICON_SORT_ALT from '@/assets/refund/icon_sort_alt.png';
import styled from 'styled-components';
import ICON_RADIO from '@/assets/refund/icon_radio.png';
import ICON_RADIO_OK from '@/assets/refund/icon_radio_ok.png';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { setFilterOrderBy } from '@/store/modules/refund';

function DateSortingFilter() {
  const [sorting, setSorting] = useState('SUPPLY_DATE');
  const dispatch = useAppDispatch();

  const changeSorting = (value: string) => {
    setSorting(value);
    dispatch(setFilterOrderBy(value));
  };

  const switchSorting = () => {
    if (sorting === 'SUPPLY_DATE') changeSorting('CREATED_DATE');
    if (sorting === 'CREATED_DATE') changeSorting('SUPPLY_DATE');
  };

  return (
    <Wrapper>
      <FilterLabel>조회 기준</FilterLabel>
      <RadioGroup>
        <RadioWrapper isOk={sorting === 'SUPPLY_DATE'}>
          {sorting === 'SUPPLY_DATE' ? (
            <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
          ) : (
            <img src={ICON_RADIO} alt='' width={24} height={24} />
          )}
          <RadioNone
            type='radio'
            name='date_sorting'
            onChange={(e) => changeSorting(e.target.value)}
            value='SUPPLY_DATE'
            checked={sorting === 'SUPPLY_DATE'}
          />
          시술일자순
        </RadioWrapper>
        <RadioWrapper isOk={sorting === 'CREATED_DATE'}>
          {sorting === 'CREATED_DATE' ? (
            <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
          ) : (
            <img src={ICON_RADIO} alt='' width={24} height={24} />
          )}
          <RadioNone
            type='radio'
            name='date_sorting'
            value='CREATED_DATE'
            onChange={(e) => changeSorting(e.target.value)}
            checked={sorting === 'CREATED_DATE'}
          />
          등록순
        </RadioWrapper>
      </RadioGroup>
    </Wrapper>
  );
}

export default DateSortingFilter;

type RadioStyle = {
  isOk: boolean;
};

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  height: 52px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 0 16px;
`;

const FilterButton = styled.button`
  display: block;
  margin-right: 16px;
  padding: 0;
`;

const IconSort = styled.img`
  display: block;
`;

const RadioNone = styled.input`
  display: none;
`;

const RadioWrapper = styled.label<RadioStyle>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  color: ${({ isOk }) => (isOk ? '#246CF6' : '#80848a')};
  cursor: pointer;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterLabel = styled.span`
  color: #999;
  font-size: 14px;
  font-weight: 600;
`;
