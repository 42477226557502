import { getRefundDepartureDate } from '@/api/refund';
import {
  basisDateRange,
  getLatestYear,
  getPreviousDate,
  halfYearList,
  monthData,
  quarterList,
  yearData,
} from '@/constants/dateRange';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import { refundDepartureDateResponse } from '@/types/refund';
import { Dropdown } from '@successmode-ktp/kui';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

type Props = {
  periodType: string;
  periodYear: string;
  period: string;
  setPeriodType: (value: string) => void;
  setPeriodYear: (value: string) => void;
  setPeriod: (value: string) => void;
};

function DateSelection({
  periodType,
  periodYear,
  period,
  setPeriodType,
  setPeriodYear,
  setPeriod,
}: Props) {
  const { globalStatus } = useGetGlobalStatus();

  const { data: departureData } = useQuery<refundDepartureDateResponse>(
    ['refundDepartureDate'],
    getRefundDepartureDate,
  );

  const periodYearOptions = useMemo(() => {
    if (departureData) {
      const startYear = new Date(departureData.startDate).getFullYear();
      const endYear = new Date(departureData.endDate).getFullYear();

      const yearList = getLatestYear(startYear, endYear);

      return yearList.filter((year) => Number(year.value) >= 2024);
    }

    return yearData;
  }, [departureData]);

  const periodOptions = useMemo(() => {
    if (periodType === 'QUARTER') {
      let quarter = [...quarterList];

      if (
        periodYear === '2024' ||
        (periodYear === '2025' && Number(period) <= 3)
      ) {
        // 4월부
        quarter = quarter.filter((year) => Number(year.value) === 4);
      }

      return quarter;
    }

    if (periodType === 'HALF_YEAR') {
      let halfYear = [...halfYearList];

      if (periodYear === '2024') {
        halfYear = halfYear.filter((year) => Number(year.value) > 1);
      }

      return halfYear;
    }

    let monthList = [...monthData];

    if (periodYear === '2024') {
      monthList = monthList.filter((month) => Number(month.value) >= 10);
    }

    return monthList;
  }, [periodYear, periodType]);

  useEffect(() => {
    if (departureData && periodType === 'MONTH') {
      const { year, month } = getPreviousDate(new Date());
      setPeriodYear(year.toString());
      setPeriod(monthData[month - 1].value);
    } else {
      setPeriod(periodOptions[0].value);
    }
  }, [departureData, periodType, periodOptions]);

  useEffect(() => {
    if (departureData && periodType === 'MONTH') {
      const { year, month } = getPreviousDate(new Date());
      setPeriodYear(year.toString());
      setPeriod(monthData[month - 1].value);
    } else {
      setPeriod(periodOptions[0].value);
    }
  }, [departureData, periodType, periodOptions]);

  return (
    <Wrapper>
      <DropdownLabel>조회 기준</DropdownLabel>
      <Dropdown
        options={basisDateRange(globalStatus?.businessType === 'INDIVIDUAL')}
        selectedValue={periodType}
        setSelectedValue={setPeriodType}
        style={{ width: '112px', marginRight: '12px' }}
      />
      <Dropdown
        options={periodYearOptions}
        selectedValue={periodYear}
        setSelectedValue={setPeriodYear}
        style={{ width: '112px' }}
      />
      <Dropdown
        options={periodOptions}
        selectedValue={period}
        setSelectedValue={setPeriod}
        style={{ width: '112px' }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const DropdownLabel = styled.label`
  color: #80848a;
  font-size: 16px;
  font-weight: 600;
  margin-right: 4px;
`;

export default DateSelection;
