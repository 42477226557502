import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import { Icon, Toggle } from '@successmode-ktp/kui';
import { useMutation } from 'react-query';
import { toggleChartStatus } from '@/api/user';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import MedicalChartNumberModal from './MedicalChartNumberModal';

function MedicalCustom() {
  const { globalStatus } = useGetGlobalStatus();
  const [isActiveChartNumber, setIsActiveChartNumber] = useState(false);
  const [isOpenChartNumberModal, setIsOpenChartNumberModal] = useState(false);

  const { mutate: mutateToggleChartStatus } = useMutation(toggleChartStatus);
  const onToggleChartStatus = () => {
    const currentStatus = !isActiveChartNumber;
    mutateToggleChartStatus(
      { chartNumberEnabled: currentStatus },
      {
        onSuccess: () => {
          setIsActiveChartNumber((prev) => !prev);
        },
      },
    );
  };

  useEffect(() => {
    if (globalStatus) {
      setIsActiveChartNumber(globalStatus?.isChartNumberEnabled);
    }
  }, [globalStatus]);

  return (
    <>
      <Container gap='16px' dir='column' flex='1'>
        <Title>기능 관리</Title>
        <AccountContent>
          <RowContainer>
            <InputContainer>
              <LabelWrapper>
                <Label>고객 차트번호 추가</Label>
                <QuestionButton onClick={() => setIsOpenChartNumberModal(true)}>
                  <Icon.QuestionFilled fillColor='#9FA1A7' size={24} />
                </QuestionButton>
              </LabelWrapper>

              <Toggle
                name='chartNumber'
                isActive={isActiveChartNumber}
                onToggle={onToggleChartStatus}
              />
            </InputContainer>
          </RowContainer>
        </AccountContent>
      </Container>
      <MedicalChartNumberModal
        isOpen={isOpenChartNumberModal}
        onClose={() => setIsOpenChartNumberModal(false)}
      />
    </>
  );
}

const Container = styled(FlexWrap)`
  margin-top: 48px;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px;
  width: 100%;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 32px;
  }
`;
const QuestionButton = styled.button`
  width: 24px;
  height: 24px;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-right: 24px;
`;
const Label = styled.label`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.mono[80]};
`;
const RowContainer = styled(FlexWrap)`
  width: 100%;
  gap: 100px;
  @media ${(props) => props.theme.largeView} {
    gap: 120px;
  }
`;
const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

const Input = styled.input.attrs({ disabled: true })`
  width: 100%;
  padding: 13px 10px;
  border-radius: 4px;
  color: ${(props) => props.theme.grayColors.scale2};
  border: none;
  background-color: ${(props) => props.theme.mono[5]};
`;

export default MedicalCustom;
