import { getComma, removeComma } from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type Props = {
  index: number;
  data: {
    productType: string;
    price: string;
    supplyPrice: string;
    vatPrice: string;
    isCheck: boolean;
  };
  isLast: boolean;
  refund: number;
  setCard: React.Dispatch<React.SetStateAction<{}>>;
  setCash: React.Dispatch<React.SetStateAction<{}>>;
};

const PortePayment = ({
  index,
  data,
  isLast,
  refund,
  setCard,
  setCash,
}: Props) => {
  const methods = useFormContext();
  const supplyPrice = removeComma(data.price);
  const refundPrice = getRefundAmount(supplyPrice);
  const totalPayment = supplyPrice - refundPrice;
  const lastRefund = refundPrice + refund;
  const lastTotalPayment = supplyPrice - lastRefund;

  useEffect(() => {
    // 결제금액
    methods.setValue(`porteRefund.${index}.price`, data.price);
    methods.setValue(
      `porteRefund.${index}.paymentCard`,
      isLast ? getComma(lastTotalPayment) : getComma(totalPayment),
    );
    methods.setValue(
      `porteRefund.${index}.refund`,
      isLast ? getComma(lastRefund) : getComma(refundPrice),
    );
    setCard((prev: any[]) => {
      return {
        ...prev,
        [`card${index}`]: isLast ? lastTotalPayment : totalPayment,
      };
    });
  }, [data.price]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    // 금액이 0으로 시작하는거 방지
    const newtarget =
      target.value.charAt(0) === '0' ? target.value.substring(1) : target.value;
    methods.setValue(
      `porteRefund.${index}.paymentCash`,
      newtarget.replace(/[^0-9]/g, ''),
    );
    let newValue = target.value.replace(/(,)/g, '');

    // 환급액
    methods.setValue(
      `porteRefund.${index}.refund`,
      isLast ? getComma(lastRefund) : getComma(refundPrice),
    );
    // 카드 결제
    methods.setValue(
      `porteRefund.${index}.paymentCard`,
      isLast
        ? getComma(lastTotalPayment - Number(newValue))
        : getComma(totalPayment - Number(newValue)),
    );
    // 현금 결제
    methods.setValue(`porteRefund.${index}.paymentCash`, getComma(newValue));

    setCash((prev: any[]) => {
      return {
        ...prev,
        [`cash${index}`]: Number(newValue),
      };
    });

    setCard((prev: any[]) => {
      return {
        ...prev,
        [`card${index}`]: isLast
          ? lastTotalPayment - Number(newValue)
          : totalPayment - Number(newValue),
      };
    });
  };

  const onChangeCash = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const price = Number(value.replaceAll(',', ''));

    const totalValue = totalPayment - getRefundAmount(totalPayment);

    if (price >= totalValue) {
      methods.setValue(
        `porteRefund.${index}.paymentCash`,
        isLast ? getComma(lastTotalPayment) : getComma(totalPayment),
      );
      methods.setValue(`porteRefund.${index}.paymentCard`, getComma(0));

      setCash((prev: any[]) => {
        return {
          ...prev,
          [`cash${index}`]: isLast ? lastTotalPayment : totalPayment,
        };
      });

      setCard((prev: any[]) => {
        return {
          ...prev,
          [`card${index}`]: 0,
        };
      });
      return;
    }
    onChange(e);
  };

  return (
    <Wrapper>
      <PaymentMethod>{index + 1}차 시술 금액</PaymentMethod>
      <Payment isFullPayment>
        <PaymentPrice
          {...methods.register(`porteRefund.${index}.price`)}
          readOnly
          type='text'
          value={data.price}
        />
        <UnitText>원</UnitText>
      </Payment>
      <PaymentWayWrapper>
        <Payment isFullPayment>
          <PaymentMethod>카드 결제</PaymentMethod>
          <PaymentPrice
            {...methods.register(`porteRefund.${index}.paymentCard`)}
            readOnly
            type='text'
          />
          <UnitText>원</UnitText>
        </Payment>
        <Payment>
          <PaymentMethod>현금 결제</PaymentMethod>
          <PaymentPrice
            {...methods.register(`porteRefund.${index}.paymentCash`)}
            type='text'
            onChange={onChangeCash}
            placeholder='현금 결제금액 입력'
          />
          <UnitText>원</UnitText>
        </Payment>
      </PaymentWayWrapper>
    </Wrapper>
  );
};

export default PortePayment;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 24px 32px;
  }
`;

const Payment = styled.div<{ isFullPayment?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
`;

const PaymentMethod = styled.p`
  padding: 12px 16px;
  font-size: ${(props) => props.theme.fontSize.xSmall};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.xSmall};
  color: ${(props) => props.theme.mono[80]};
`;

const PaymentPrice = styled.input<{ readOnly?: boolean }>`
  border: ${(props) =>
    props.readOnly ? 'none' : `1px solid ${props.theme.mono[20]}`};
  background-color: ${(props) =>
    props.readOnly ? props.theme.mono[5] : '#ffffff'};
  flex: 0.8;
  border-radius: 4px;
  padding: 16px 32px 16px 16px;
  text-align: right;
  ::placeholder {
    color: ${(props) => props.theme.mono[50]};
  }
  font-size: ${(props) => props.theme.fontSize.xSmall};
  min-width: 120px;
  max-width: 212px;
  @media ${(props) => props.theme.mediumView} {
    flex: 1;
  }
`;

const UnitText = styled.span`
  transform: translateX(-24px);
  font-size: 12px;
  color: ${(props) => props.theme.mono[35]};
`;

const PaymentWayWrapper = styled.div`
  display: flex;
`;
