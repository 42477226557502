import { comma } from '@/util/format';
import { Button, Icon } from '@successmode-ktp/kui';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import TableHeader from '../refund/PaymentComplete/TableHeader';
import refundNotImage from '@/assets/refund/refundNot.png';
import RefundDetailModal from './RefundDetailModal';
import {
  PERIOD_TYPE_LIST,
  refundDeparture4MonthsResponse,
  refundDeparture4MonthSummary,
  refundDepartureSummaryResponse,
} from '@/types/refund';
import { halfYearList, quarterList } from '@/constants/dateRange';
import { format } from 'date-fns';
import {
  getRefundDeparture4Months,
  getRefundDepartureSummary,
} from '@/api/refund';
import { useQuery } from 'react-query';
import { RefundDepartureListHeader } from '@/constants/refund';
import DataDivision from './DataDivision';
import DateSelection from './DateSelection';

function RefundDepartureContent() {
  const [periodType, setPeriodType] = useState('MONTH');
  const [periodYear, setPeriodYear] = useState<string | number>('');
  const [period, setPeriod] = useState<string | number>('');

  const { data: summaryData } = useQuery<refundDepartureSummaryResponse>(
    ['refundDepartureSummary', periodYear, period],
    () =>
      getRefundDepartureSummary({
        periodType: periodType as PERIOD_TYPE_LIST,
        periodYear: Number(periodYear),
        period: Number(period),
      }),
    {
      enabled: !!periodYear && !!period,
      keepPreviousData: true,
    },
  );
  const { data: fourMonthsData } = useQuery<refundDeparture4MonthsResponse>(
    ['refundDeparture4Months', periodYear, period],
    () =>
      getRefundDeparture4Months({
        periodType: periodType as PERIOD_TYPE_LIST,
        periodYear: Number(periodYear),
        period: Number(period),
      }),
    {
      enabled: !!periodYear && !!period,
      keepPreviousData: true,
    },
  );

  const [detailData, setDetailData] = useState<{
    year: number;
    month: number;
    count: number;
    totalRefund: number;
  }>({
    year: 0,
    month: 0,
    count: 0,
    totalRefund: 0,
  });
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);

  const onCloseDetail = () => {
    setDetailData({
      year: 0,
      month: 0,
      count: 0,
      totalRefund: 0,
    });
    setIsOpenDetailModal(false);
  };

  const onOpenDetailModal = (data: refundDeparture4MonthSummary) => {
    let year = 0;
    let month = 0;

    if (data.key !== null) {
      month = getPeriod(
        periodType as 'HALF_YEAR' | 'QUARTER' | 'MONTH',
        data.key as null | 'CURRENT' | 'NEXT',
      );
      year = getYear(month - 1, data.key as null | 'CURRENT' | 'NEXT');
    }

    if (periodType === 'MONTH') {
      year = data.key ? Number(format(new Date(data.key), 'yyyy')) : 0;
      month = data.key ? Number(format(new Date(data.key), 'MM')) : 0;
    }

    setDetailData({
      year,
      month,
      count: data.count,
      totalRefund: data.totalRefund,
    });
    setIsOpenDetailModal(true);
  };

  const RefundData = [
    {
      label: '외국인 환자 택스리펀 건',
      value: summaryData?.count || 0,
      unit: '건',
      icon: <Icon.My fillColor='#5089F8' />,
    },
    {
      label: '출국 완료',
      value: summaryData?.departureCompleteCount || 0,
      unit: '건',
      tooltip: '출국 완료 시점으로 부가세 신고에 반영됩니다.',
    },
    {
      label: '출국 확인중',
      value: summaryData?.departureNotCompleteCount || 0,
      unit: '건',
      tooltip: (
        <>
          시술 후 3개월 이내에 출국이 확인되면
          <br />
          [출국 완료] 건으로 반영 됩니다.
        </>
      ),
    },
    {
      label: '환급액',
      value: summaryData?.totalRefund || 0,
      unit: '원',
      icon: <Icon.Refund fillColor='#5089F8' size={28} />,
    },
  ];

  const departureLabel = useCallback(
    (key: string | null) => {
      if (key === null && periodType !== 'MONTH') {
        return '출국 확인중';
      }

      const hasKey = key === 'CURRENT' || key === 'NEXT';

      if (periodType === 'HALF_YEAR' && hasKey) {
        const index = getPeriod('HALF_YEAR', key) - 1;
        const year = getYear(index, key) - 2000;
        return `${year}년 ${halfYearList[index].label} 출국`;
      }

      if (periodType === 'QUARTER' && hasKey) {
        const index = getPeriod('QUARTER', key) - 1;
        const year = getYear(index, key) - 2000;
        return `${year}년 ${quarterList[index].label} 출국`;
      }

      if (periodType === 'MONTH' && !(key === 'CURRENT' || key === 'NEXT')) {
        if (!key) {
          return '출국 확인중';
        }
        const month = format(new Date(key), 'MM');
        return `${month}월 출국`;
      }

      return '';
    },
    [periodType, period, periodYear],
  );

  const getPeriod = (
    periodType: 'HALF_YEAR' | 'QUARTER' | 'MONTH',
    key: null | 'CURRENT' | 'NEXT',
  ) => {
    const maxNumber = periodType === 'HALF_YEAR' ? 2 : 4;
    return key === 'CURRENT'
      ? Number(period)
      : Number(period) < maxNumber
      ? Number(period) + 1
      : 1;
  };

  const getYear = (index: number, key: null | 'CURRENT' | 'NEXT') => {
    return index === 0 && key === 'NEXT'
      ? Number(periodYear) + 1
      : Number(periodYear);
  };

  return (
    <>
      <Container>
        <Section>
          <DateSelection
            periodType={periodType}
            periodYear={periodYear as string}
            period={period as string}
            setPeriodType={setPeriodType}
            setPeriodYear={setPeriodYear}
            setPeriod={setPeriod}
          />
          <DataDivision data={RefundData} />
          <Table>
            <TableHeader titleList={RefundDepartureListHeader} hasNotOption />
            {fourMonthsData?.summaries &&
            fourMonthsData?.summaries?.length > 0 ? (
              <TableList>
                {fourMonthsData.summaries.map((data, index) => (
                  <Item key={data.key} isActive={index === 0}>
                    <Content flex={1}>{departureLabel(data.key)}</Content>
                    <Content flex={1}>{comma(data.count)}건</Content>
                    <Content flex={1}>{comma(data.totalRefund)}원</Content>
                    <Content flex={1}>
                      <Button
                        size='sm'
                        enabled
                        style={{ width: 'auto', padding: '12px' }}
                        onClick={() => onOpenDetailModal(data)}
                      >
                        상세보기
                      </Button>
                    </Content>
                  </Item>
                ))}
              </TableList>
            ) : (
              <RefundNotContainer>
                <Image src={refundNotImage} />
                <Text>검색 결과가 없습니다.</Text>
              </RefundNotContainer>
            )}
          </Table>
        </Section>
      </Container>
      {isOpenDetailModal && (
        <RefundDetailModal
          recordPeriod={{
            periodType: periodType as PERIOD_TYPE_LIST,
            periodYear: Number(periodYear),
            period: Number(period),
          }}
          detailData={detailData}
          isOpen={isOpenDetailModal}
          onClose={onCloseDetail}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 40px 85px 40px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: #fff;
`;

const Table = styled.div`
  width: 100%;
`;

const TableList = styled.ul``;

const Item = styled.li<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  background-color: #fff;
  ${({ isActive = false }) =>
    isActive
      ? css`
          font-weight: 700;
          color: #3a3b3e;
          height: 120px;
        `
      : css`
          font-weight: 400;
          color: #80848a;
        `}

  > div:first-child {
    color: ${({ isActive = false }) => (isActive ? '#246CF6' : '#80848a')};
  }
`;

const Content = styled.div<{
  flex: number;
}>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 24px;
`;

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
  padding: 24px 0;
`;

const Image = styled.img`
  width: 72px;
  height: 72px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[50]};
`;

export default RefundDepartureContent;
