import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';

import callIcon from '@/assets/refund/call.png';
import kakaoIcon from '@/assets/refund/kakao.png';
import menuBarIcon from '@/assets/common/menuBar.png';
import { refundRegister, temporaryStorageLookUp } from '@/api/refund';
import { forceRegiser, passportValidate } from '@/api/tourist/indext';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import useScanPassportInfo from '@/hooks/useScanPassportInfo';
import useValidate from '@/hooks/useValidate';
import {
  birthDateUpdateState,
  reset,
  setDirectPassport,
  setDirectPaymnet,
  updateRefundInfo,
} from '@/store/modules/refund';
import {
  paymentTypeState,
  refundIndexState,
  touristIndexState,
} from '@/store/modules/user';
import {
  kioskRefundModalState,
  passportCheckState,
  storageModalState,
} from '@/store/modules/validate';
import {
  IPassportValidatePayload,
  IPassportValidateResponse,
  IRefundPayload,
  IRefundResponse,
  TemporaryStorageLookUpResponse,
} from '@/types/refund';
import { getRefundAlertMessage } from '@/util/errorMessage';
import {
  attachedHyphensDate,
  convertToKoreanUnit,
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
} from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';
import ErrorMessage from '../common/ErrorMessage';
import FlexWrap from '../common/FlexWrap';
import Icon from '../common/Icon';
import LoadingView from '../common/LoadingView';
import Typography from '../common/Typography';
import BirthDate from './BirthDate';
import ExpireDateModal from './ExpireDateModal';
import KioskStoreRefundModal from './KioskStoreRefundModal';
import TemporaryStorageModal from './TemporaryStorageModal';
import ValidateKorModal from './ValidateKorModal';
import { updateModal } from '@/store/modules/modal';
import errorIcon from '@/assets/common/error.png';
import confirmIcon from '@/assets/common/confirm.png';
import { updateLoading } from '@/store/loading.store';
import ExpiredDateModal from '../common/ExpiredDate.modal';
import PorteRefundModal from './porte/PorteRefund.modal';
import { DIRECT_PRICE, isCustom } from '@/constants/common';
import ICON_RADIO from '@/assets/refund/icon_radio.png';
import ICON_RADIO_OK from '@/assets/refund/icon_radio_ok.png';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

interface IProps {
  setLeafletModalInfo: Dispatch<
    SetStateAction<{
      active: boolean;
      isOver600: boolean;
      isKioskError: boolean;
    }>
  >;
  setIsRefund: Dispatch<SetStateAction<boolean>>;
  setIsPayment: Dispatch<SetStateAction<boolean>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isExpired: boolean;
  setisCustomKor: React.Dispatch<React.SetStateAction<boolean>>;
  setisCustomLeaflet: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenChartModal: (state: boolean) => void;
  isOpenChartModal?: boolean;
}
export interface ITourist {
  supplyDate: string;
  name: string;
  nationality: string;
  passportNumber: string;
  saleDate: string;
  birthYear: string;
  birthMonth: string;
  birthDay: string;
}

function TouristInput({
  setLeafletModalInfo,
  setIsRefund,
  setIsPayment,
  isExpired,
  setIsOpen,
  setisCustomKor,
  setisCustomLeaflet,
  setIsOpenChartModal,
  isOpenChartModal,
}: IProps) {
  const { globalStatus } = useGetGlobalStatus();
  const { hospitalId } = useAppSelector((state) => state.user);
  const [validatetErrorMessage, setValidateErrorMessage] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isExpireDateFailed, setIsExpireDateFailed] = useState(false);
  const [expireDateModal, setExpireDateModal] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [isValidateKorModal, setIsValidateKorModal] = useState(false);
  const [errorCheckToggle, setErrorCheckToggle] = useState(false);
  const [isOpenExpired, setIsOpenExpired] = useState(false);

  const dropDownRef = useRef<HTMLUListElement>(null);
  let inputValueRef = useRef({ label: '', value: '' });
  let timeOutRef = useRef<any>(null);

  const { expireDate, setExpireDate, onChange, createFormattedBirthDate } =
    useScanPassportInfo();
  const { checkCorrectDate, checkSupplyDate } = useValidate();

  const { data: tempList } = useQuery<TemporaryStorageLookUpResponse[], number>(
    ['allStorage'],
    () => temporaryStorageLookUp(hospitalId),
    {},
  );

  // 키오스크 환급 API
  const { mutate: refundMutation } = useMutation<
    IRefundResponse,
    AxiosError,
    IRefundPayload
  >((payload) => refundRegister(payload), {
    retry: false,
    onSuccess: (data) => {
      dispatch(refundIndexState(data));
      dispatch(reset());
      setIsRefund(true);
      setIsPayment(false);
      dispatch(updateLoading({ isLoading: false }));
    },
    onError: (error) => {
      console.log('에러', error);
      dispatch(updateLoading({ isLoading: false }));
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    getValues,
    setFocus,
    setValue,
    watch,
  } = useFormContext();
  const {
    birthDate,
    isDirectPayment,
    isScanned,
    temporaryStorage,
    isDirectPassport,
    gender,
  } = useAppSelector((state) => state.refund);
  const { isKioskStore, touristIndex, isAutoPayment } = useAppSelector(
    (state) => state.user,
  );
  const { isStorageModal, isPassportCheck, isKioskRefundModal } =
    useAppSelector((state) => state.validate);
  const dispatch = useAppDispatch();

  // 드롭박스 스크롤 관련코드
  useEffect(() => {
    if (isDropDownOpen && dropDownRef.current && selectedItemIndex !== -1) {
      const itemHeight = dropDownRef.current.children[0]?.clientHeight;
      dropDownRef.current.scrollTop =
        selectedItemIndex * itemHeight - dropDownRef.current.clientHeight / 2;
    }
  }, [isDropDownOpen, selectedItemIndex]);
  const [isFullFilled, setIsFullFilled] = useState(false);

  useEffect(() => {
    const { year, month, day } = expireDate;
    const { touristName, nationality, passportNumber, saleDate, gender } =
      getValues();
    year &&
    month &&
    day &&
    touristName &&
    nationality &&
    passportNumber &&
    saleDate &&
    !isPassportCheck
      ? setIsActive(true)
      : setIsActive(false);
    setIsFullFilled(
      !!(
        touristName.trim() &&
        nationality &&
        passportNumber &&
        year &&
        day &&
        month.value
      ),
    );
  }, [getValues(), expireDate]);

  // 환급하기 페이지 들어오면 성명 바로 입력하도록 포커싱
  useEffect(() => {
    setFocus('touristName');
  }, []);

  // 여권조회 성공후 로직
  const handleSuccess = async (data: IPassportValidateResponse) => {
    setIsActive(false);
    setValidateErrorMessage('');
    setValue('chartNumber', data.chartNumber);
    dispatch(touristIndexState(data));
    dispatch(passportCheckState(true));
    dispatch(paymentTypeState(data.paymentType === 'ORIGINAL'));

    if (
      temporaryStorage.supplyDate &&
      Boolean(temporaryStorage.supply[0].productType)
    ) {
      dispatch(kioskRefundModalState(false));
    } else {
      dispatch(kioskRefundModalState(true));
    }

    // 여권조회시 600초과이면 전액결제 방식으로 변경
    let newTotalPayPrice = 0;
    getValues('supply').forEach((item: any) => {
      newTotalPayPrice += Number(String(item.price).replace(/(,)/g, ''));
    });

    if (newTotalPayPrice > DIRECT_PRICE) {
      dispatch(setDirectPaymnet(true));
    }

    // 여권조회를 다시 햇을때는 이미 전액결제 상태면 다시 전액결제 상태가 되도록
    if (isDirectPayment || isDirectPassport) {
      dispatch(setDirectPaymnet(true));
    } else {
      dispatch(setDirectPaymnet(data.isDirect));
    }
    dispatch(setDirectPassport(data.isDirect));

    // 고객 차트번호 입력 모달
    setIsOpenChartModal(
      (globalStatus?.isChartNumberEnabled && data.chartNumber === null) ||
        false,
    );

    // 키오스크 고장일 경우 모달
    if (data.hasKioskError) {
      setLeafletModalInfo((prev) => ({
        ...prev,
        active: true,
        isKioskError: data.hasKioskError,
      }));
    }
    // 차감병원이 체류기간, 한국인 경우 모달
    if (data.isDirect) {
      setLeafletModalInfo((prev) => ({
        ...prev,
        active: true,
        isKioskError: false,
      }));
    }

    setIsExpireDateFailed(false);

    setisCustomKor(data.isDirect);
    setisCustomLeaflet(data.isDirect);

    if (isScanned) return;

    const newBirthDate = createFormattedBirthDate();
    dispatch(birthDateUpdateState(newBirthDate));

    if (data.expireDateLookupFailed && !expireDateModal) {
      setIsExpireDateFailed(true);
      setExpireDateModal(true);
      dispatch(kioskRefundModalState(false));
      setExpireDate((prev) => ({
        ...prev,
        year: getValues('birthYear'),
        day: getValues('birthDay'),
      }));
      return;
    }

    if (!data.isDirect) {
      setIsOpen(true);
    }

    if (!isCustom(hospitalId)) {
      dispatch(
        updateModal({
          isActive: true,
          type: 'CUSTOM',
          data: {
            icon: confirmIcon,
            title: '여권 인증 성공',
            content: '여권 인증이 완료되었습니다.',
            btnText: '확인',
            subBtnText: '',
            onClose: onCloseModal,
            btnCallback:
              globalStatus?.isChartNumberEnabled && data.chartNumber === null
                ? () => {
                    const chartInput = document.getElementById('chartNumber');
                    chartInput?.focus();
                  }
                : isKioskStore
                ? () => setFocus('totalPrice')
                : onCloseModal,
            // btnCallback: isKioskStore
            //   ? () => setFocus('totalPrice')
            //   : () => dispatch(dropListState({ isDrop: true, index: 0 })),
          },
        }),
      );
    }
  };

  // 강제등록
  const { mutate: forceRefundMutation, isLoading: isLoadingForce } =
    useMutation<
      IPassportValidateResponse,
      AxiosError,
      IPassportValidatePayload
    >(['forceRefund'], (payload) => forceRegiser(payload), {
      onSuccess: (data) => {
        handleSuccess(data);
        setIsValidateKorModal(false);
      },
    });

  // 여권조회 API
  const { mutate: validateMutation, isLoading } = useMutation<
    IPassportValidateResponse,
    AxiosError,
    IPassportValidatePayload
  >((payload) => passportValidate(payload), {
    retry: false,
    onSuccess: async (data) => {
      handleSuccess(data);
    },
    onError: (error: any) => {
      const message = error.response.data.message;
      const code = error.response.data.code;

      if (
        message.includes('4005') &&
        getValues('nationality').toUpperCase() === 'KOR'
      ) {
        setIsValidateKorModal(true);
        return;
      }
      if (message.includes('4005')) {
        setError('passportNumber', {
          message: '존재하지 않는 여권번호입니다. 다시 확인해주세요.',
        });
        return;
      }
      if (message.includes('4001')) {
        setError('passportNumber', {
          message: '국적코드와 여권번호가 일치하지 않습니다',
        });
        return;
      }
      if (['T0002', 'T0003'].includes(code)) {
        setValidateErrorMessage('환급 대상이 아니므로 환급이 불가합니다.');
        return;
      }
      alert(getRefundAlertMessage(message));
    },
  });

  // 여권조회
  const onSubmit: SubmitHandler<FieldValues> = async ({
    supplyDate,
    touristName,
    nationality,
    passportNumber,
    saleDate,
  }) => {
    if (!!Object.keys(errors).length) return;
    if (!checkSupplyDate(supplyDate)) return;

    if (isExpired) {
      return setIsOpenExpired(true);
    }

    const { year, month, day } = expireDate;
    const newBirthDay = day.padStart(2, '0');
    const newBirthDate = createFormattedBirthDate();

    if (!checkCorrectDate(year, month.value, newBirthDay)) {
      setError('birthYear', {
        message: '올바른 생년월일을 입력해주세요.',
      });
      return;
    }

    // 환급하기에서 입력한 생년월일과 생년월일 확인 안내창에서 입력한 생년월일이 같은면 서버 요청안함
    if (newBirthDate === birthDate && !temporaryStorage.supplyDate) {
      setIsExpireDateFailed(false);
      return;
    }
    const payload = {
      name: touristName.toUpperCase(),
      nationality: nationality.toUpperCase(),
      passportNumber: passportNumber.toUpperCase(),
      birthDate: newBirthDate,
      saleDate,
      isScanned,
      gender: watch('gender'),
    };

    setValue('chartNumber', '');
    validateMutation(payload);
  };

  const methods = useFormContext();
  // 여권정보 수정
  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(passportCheckState(false));
    setExpireDateModal(false);
    dispatch(birthDateUpdateState(''));

    // 커스텀 의원인 경우
    if (true) {
      const supplyList = methods.getValues('supply') || [];
      supplyList.map((supply: any, index: number) => {
        methods.setValue(`supply.${index}.price`, '');
        methods.setValue(`supply.${index}.productType`, '');
        methods.setValue(`supply.${index}.supplyPrice`, '');
        methods.setValue(`supply.${index}.vatPrice`, '');
      });
      methods.setValue('porteRefund', []);
    }
  };

  // 키오스크 환급
  const onRefund = (e: any) => {
    e.preventDefault();
    const {
      touristName,
      supplyDate,
      totalPrice,
      paymentCard,
      paymentCash,
      productType,
      gender,
    } = getValues();

    const totalRefund = getRefundAmount(+totalPrice.replace(/(,)/g, ''));
    const numTotalPrice = totalPrice.replace(/(,)/g, '');

    const orderInfo = {
      productType: productType,
      price: String(totalPrice).replace(/(,)/g, ''),
      supplyPrice: getComma(getSupplyPaymentPrice(numTotalPrice)),
      vatPrice: getComma(
        getVatPaymentPrice(
          numTotalPrice,
          getSupplyPaymentPrice(numTotalPrice).toString(),
        ),
      ),
    };

    if (+numTotalPrice < 15000) {
      setError('totalPrice', {
        message: '최소 금액 15,000원 이상 입니다.',
      });
      return;
    }

    if (!orderInfo) {
      alert('시술 내역을 입력해주세요.');
      return;
    }

    const payload = {
      touristName: touristName.toUpperCase(),
      supplyDate,
      totalRefund: String(totalRefund),
      totalPrice: String(totalPrice).replace(/(,)/g, ''),
      paymentCard: String(paymentCard).replace(/(,)/g, ''),
      paymentCash: String(paymentCash).replace(/(,)/g, ''),
      touristId: String(touristIndex),
      orderInfoList: [orderInfo],
      paymentType: !isAutoPayment || isDirectPayment ? 'DIRECT' : 'ORIGINAL',
      isScanned,
      tempInformationId: temporaryStorage.tempInformationId,
      gender: gender,
    };

    dispatch(
      updateModal({
        isActive: true,
        type: 'PRICE_CHECK',
        data: {
          icon: '',
          title: '결제금액을 확인해주세요.',
          content: convertToKoreanUnit(+totalPrice.replace(/(,)/g, '')) + '원',
          btnText: '확인',
          subBtnText: '다시 입력',
          onClose: onCloseModal,
          btnCallback: () => {
            dispatch(updateLoading({ isLoading: true }));
            refundMutation(payload);
          },
        },
      }),
    );
  };

  const reFreshOnSubmit = () => {
    setErrorCheckToggle((prev) => !prev);
    handleSubmit(onSubmit);
  };

  const onCloseModal = () => {
    dispatch(updateModal({ isActive: false, data: null, type: null }));
  };

  useEffect(() => {
    if (isLoading) {
      timeOutRef.current = setTimeout(() => {
        dispatch(
          updateModal({
            isActive: true,
            type: 'CUSTOM',
            data: {
              icon: errorIcon,
              title: '서버 통신 오류',
              content: '통신 오류로 다시 시도해주세요.',
              btnText: '다시 시도',
              subBtnText: '닫기',
              btnCallback: reFreshOnSubmit,
              onClose: onCloseModal,
            },
          }),
        );
      }, 5000);
    } else {
      clearTimeout(timeOutRef.current);
    }
  }, [isLoading, errorCheckToggle]);

  const isError = !!Object.keys(errors).filter(
    (error) =>
      error !== 'birthYear' && error !== 'birthDay' && error !== 'supplyDate',
  ).length;

  useEffect(() => {
    setValue('gender', gender);
  }, [gender]);

  return (
    <Container>
      <Form>
        <FlexWrap alignItems='center' justifyContent='space-between'>
          <FlexWrap gap='32px'>
            <Title>외국인 관광객 정보</Title>
            <TemporaryStorage onClick={() => dispatch(storageModalState(true))}>
              <Icon imgUrl={menuBarIcon} width='16px' height='16px' />
              <Typography color='#5F6165'>
                임시 저장 목록(
                {tempList ? tempList.length?.toString().padStart(2, '0') : '00'}
                건)
              </Typography>
            </TemporaryStorage>
          </FlexWrap>
          <FlexWrap gap='24px'>
            <FlexWrap gap='8px'>
              <Icon imgUrl={callIcon} width='24px' height='24px' />
              <Typography size='14px' fontWeight='500'>
                T. 02-6275-8011
              </Typography>
            </FlexWrap>
            <Link href='http://pf.kakao.com/_fFgsb/chat' target='_blank'>
              <Icon imgUrl={kakaoIcon} width='24px' height='24px' />
              <Typography size='14px' fontWeight='500'>
                카카오톡 채널 문의하기
              </Typography>
            </Link>
          </FlexWrap>
        </FlexWrap>
        <InputWrapper>
          <InputContainer>
            {/* 시술일자 */}
            <InputContent>
              <Label>시술일자</Label>
              <Input
                {...register('supplyDate')}
                maxLength={10}
                type='text'
                disabled={isPassportCheck}
                placeholder='YYYYMMDD'
                autoComplete='off'
                onChange={(e) => {
                  setValue('supplyDate', attachedHyphensDate(e.target.value));
                }}
              />
              {errors.supplyDate && (
                <ErrorMessage
                  text={String(errors.supplyDate.message)}
                  absolute
                />
              )}
            </InputContent>
            {/* 성별 */}
            <InputContent>
              <Label>성별</Label>
              <RadioGroup>
                <RadioWrapper isOk={watch('gender') === 'F'}>
                  {watch('gender') === 'F' ? (
                    <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
                  ) : (
                    <img src={ICON_RADIO} alt='' width={24} height={24} />
                  )}
                  <RadioNone
                    type='radio'
                    name='gender'
                    onChange={() => setValue('gender', 'F')}
                    value='F'
                    checked={watch('gender') === 'F'}
                    disabled={isPassportCheck}
                  />
                  여성
                </RadioWrapper>

                <RadioWrapper isOk={watch('gender') === 'M'}>
                  {watch('gender') === 'M' ? (
                    <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
                  ) : (
                    <img src={ICON_RADIO} alt='' width={24} height={24} />
                  )}
                  <RadioNone
                    type='radio'
                    name='gender'
                    value='M'
                    onChange={() => setValue('gender', 'M')}
                    checked={watch('gender') === 'M'}
                    disabled={isPassportCheck}
                  />
                  남성
                </RadioWrapper>
              </RadioGroup>
              {errors.supplyDate && (
                <ErrorMessage
                  text={String(errors.supplyDate.message)}
                  absolute
                />
              )}
            </InputContent>
          </InputContainer>
          <InputContainer>
            {/* 성명 */}
            <InputContent>
              <Label>성명</Label>
              <Input
                {...register('touristName', {
                  required: true,
                })}
                type='text'
                disabled={isPassportCheck}
                placeholder='영문만 입력(성 이름 기재)'
                autoComplete='off'
                isKoreaWord={!!errors.touristName}
                onChange={(e) => onChange(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
              {errors.touristName && (
                <ErrorMessage
                  text={String(errors.touristName.message)}
                  absolute
                />
              )}
            </InputContent>
            {/* 여권번호 */}
            <InputContent>
              <Label>여권번호</Label>
              <Input
                {...register('passportNumber', {
                  required: true,
                })}
                type='text'
                disabled={isPassportCheck}
                placeholder='여권번호 입력'
                autoComplete='off'
                autoCapitalize='characters'
                onChange={(e) => onChange(e)}
              />
              {errors.passportNumber && (
                <ErrorMessage
                  text={String(errors.passportNumber.message)}
                  absolute
                />
              )}
            </InputContent>
          </InputContainer>
          <InputContainer>
            {/* 생년월일 */}
            <InputContent>
              <BirthDate
                expireDate={expireDate}
                setExpireDate={setExpireDate}
                setIsDropDownOpen={setIsDropDownOpen}
                inputValueRef={inputValueRef}
                selectedItemIndex={selectedItemIndex}
                isDropDownOpen={isDropDownOpen}
                setSelectedItemIndex={setSelectedItemIndex}
                dropDownRef={dropDownRef}
              />
            </InputContent>
            {/* 국적 */}
            <InputContent>
              <Label>국적</Label>
              <Input
                {...register('nationality', {
                  required: true,
                  minLength: { value: 3, message: '3글자로 입력해주세요.' },
                  maxLength: { value: 3, message: '3글자로 입력해주세요.' },
                })}
                type='text'
                disabled={isPassportCheck}
                placeholder='거주국가(국적)'
                autoComplete='off'
                maxLength={3}
                onChange={(e) => onChange(e)}
              />
              {errors.nationality && (
                <ErrorMessage
                  text={String(errors.nationality.message)}
                  absolute
                />
              )}
            </InputContent>
          </InputContainer>
          <ButtonContainer>
            {validatetErrorMessage && (
              <ErrorMessage text={validatetErrorMessage} margin='0 10px 0 0' />
            )}
            <Button
              isActive={(isActive && isFullFilled) || isError}
              isValidate={(isPassportCheck && isFullFilled) || isError}
              disabled={isLoading || isError || !isFullFilled}
              onClick={isPassportCheck ? onEdit : handleSubmit(onSubmit)}
            >
              {isLoading ? (
                <LoadingView height='100%' />
              ) : isPassportCheck ? (
                '정보 수정'
              ) : (
                '여권 확인'
              )}
            </Button>
          </ButtonContainer>
        </InputWrapper>
      </Form>
      {isExpireDateFailed && (
        <ExpireDateModal
          isLoading={isLoading}
          onSubmit={onSubmit}
          expireDate={expireDate}
          setExpireDate={setExpireDate}
          inputValueRef={inputValueRef}
          selectedItemIndex={selectedItemIndex}
          setSelectedItemIndex={setSelectedItemIndex}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={setIsDropDownOpen}
          dropDownRef={dropDownRef}
        />
      )}

      {/* 커스텀 의원 */}
      {!isCustom(hospitalId) &&
        isKioskStore &&
        isPassportCheck &&
        isKioskRefundModal && (
          <KioskStoreRefundModal
            onRefund={onRefund}
            setLeafletModalInfo={setLeafletModalInfo}
            onOpenChartModal={() => setIsOpenChartModal(true)}
            isOpenedChartModal={
              globalStatus?.isChartNumberEnabled && isOpenChartModal
            }
          />
        )}
      {/* 커스텀 의원 */}

      {tempList && isStorageModal && <TemporaryStorageModal data={tempList} />}
      {isValidateKorModal && (
        <ValidateKorModal
          forceRefundMutation={forceRefundMutation}
          isLoading={isLoadingForce}
          onChange={onChange}
          createFormattedBirthDate={createFormattedBirthDate}
        />
      )}
      {isOpenExpired && (
        <ExpiredDateModal onClose={() => setIsOpenExpired(false)} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 28px;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 38px 52px;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
  @media ${(props) => props.theme.laptop} {
    gap: 18px;
    padding: 38px 20px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 100px;
  position: relative;
  @media ${(props) => props.theme.largeView} {
    gap: 120px;
  }
`;

const InputContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  color: ${(props) => props.theme.mono[80]};
`;

const Input = styled.input<{ isKoreaWord?: boolean }>`
  width: 80%;
  height: 48px;
  border-radius: 4px;
  padding-left: 10px;
  outline: none;
  text-transform: uppercase;
  border: 1.5px solid
    ${(props) =>
      props.isKoreaWord
        ? props.theme.priamryColors.error
        : props.theme.mono[20]};
  color: ${(props) =>
    props.isKoreaWord ? props.theme.priamryColors.error : '#212121'};
  :disabled {
    background-color: ${(props) => props.theme.mono[5]};
    color: ${(props) => props.theme.mono[65]};
  }
  :focus {
    background-color: ${(props) => props.theme.blue[5]};
    border-color: ${(props) =>
      props.isKoreaWord
        ? props.theme.priamryColors.error
        : props.theme.blue[100]};
  }
  ::placeholder {
    color: ${(props) =>
      props.isKoreaWord
        ? props.theme.priamryColors.error
        : props.theme.mono[35]};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Button = styled.button<{ isActive?: boolean; isValidate?: boolean }>`
  width: 240px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 150%;
  padding: 14px 0;
  border-radius: 8px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.isValidate || props.isActive
      ? props.theme.pointColors.white
      : props.theme.pointColors.white};
  background-color: ${(props) =>
    props.isValidate
      ? props.theme.mono[65]
      : props.isActive
      ? props.theme.blue[100]
      : props.theme.blue[20]};
`;

const TemporaryStorage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const Link = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const RadioWrapper = styled.label<{ isOk: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  color: ${({ isOk }) => (isOk ? '#246CF6' : '#80848a')};
  cursor: pointer;
`;

const RadioNone = styled.input`
  display: none;
`;

const RadioGroup = styled.div`
  width: 80%;
  display: flex;
  gap: 24px;
`;

export default TouristInput;
