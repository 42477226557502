import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import refundNotImage from '@/assets/refund/refundNot.png';
import saveIcon from '@/assets/refund/save.png';
import {
  RefundGraphFullPaymentHeader,
  RefundGraphFullPaymentHeaderWithKiosk,
  RefundGraphHeader,
} from '@/constants/refundGraph';
import Button from '@/components/common/Button';
import FlexWrap from '@/components/common/FlexWrap';
import HospitalsFilter from '@/components/common/HospitalsFilter';
import Paging from '@/components/common/Paging';
import SupplyFilter from '@/components/common/SupplyFilter';
import TableHeader from '@/components/refund/PaymentComplete/TableHeader';

import ViewSelector from '@/components/common/ViewSelector';
import DateSelector from '@/components/common/DateSelector';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { useRefndDetail } from '@/hooks/useRefundDetail';
import { IRefundDetailContent } from '@/types/refund';
import LoadingView from '@/components/common/LoadingView';
import RefundDetailItem from '@/components/refundDetail/RefundDetailItem';
import SearchBar from './SearchBar';
import DateSortingFilter from './DateSortingFilter';
import { useQuery } from 'react-query';
import { IMedicalResponse } from '@/types/medical';
import { AxiosError } from 'axios';
import { medicalInfo } from '@/api/medical';
import { useEffect, useMemo, useState } from 'react';
import TableColumnFilter from './TableColumnFilter';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function RefundDetailList() {
  const {
    data,
    totalPage,
    refetch,
    pageNumber,
    setPageNumber,
    setSize,
    excelDown,
    isLoading,
    setKeyword,
  } = useRefndDetail();
  const { globalStatus } = useGetGlobalStatus();
  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const methods = useForm({
    defaultValues: {
      checked: false,
    },
  });

  const { data: medicalData } = useQuery<IMedicalResponse, AxiosError>(
    'medical',
    medicalInfo,
    {
      retry: false,
      onSuccess: (res) => {},
      onError: (error) => {
        console.log('error', error);
      },
    },
  );

  const onClickDownload = () => {
    excelDown();
  };

  const showKioskPrice = useMemo(() => {
    // 엔젤미의원(8474100402)에서는 키오스크 환급액 column 보이기
    return medicalData?.hospitalInfoDto?.businessNumber === '8474100402';
  }, [medicalData]);

  useEffect(() => {
    const showColumnValue = localStorage.getItem('showColumn');
    if (showColumnValue) {
      setSelectedFilters(JSON.parse(showColumnValue));
    } else {
      const columnsList = (
        globalStatus?.isChartNumberEnabled ? [{ title: '차트번호' }] : []
      )
        .concat(
          isAutoPayment
            ? RefundGraphHeader
            : showKioskPrice
            ? RefundGraphFullPaymentHeaderWithKiosk
            : RefundGraphFullPaymentHeader,
        )
        .map(({ title }) => title)
        .filter(
          (title) =>
            !['No.', '구분', '환급상태', '환급 서류', '취소'].includes(title),
        );

      setSelectedFilters(columnsList.filter((item) => item !== '성별'));
    }
  }, [globalStatus, isAutoPayment, showKioskPrice]);

  return (
    <FormProvider {...methods}>
      <Header>
        <SubTitle>환급 내역</SubTitle>
        <FlexWrap gap='10px' alignItems='center'>
          <ViewSelector setPageNumber={setPageNumber} setSize={setSize} />
          {hospitals.length > 0 && (
            <HospitalsFilter setPageNumber={setPageNumber} />
          )}
          <SupplyFilter setPageNumber={setPageNumber} />
          <DateSelector isRefundDetail setPageNumber={setPageNumber} />
          <Button
            padding='5px 12px'
            text='환급 내역 엑셀 다운로드'
            backgroundColor='#5F6165'
            color='#FFFFFF'
            borderRadius='8px'
            onClick={onClickDownload}
            reverse
            gap='8px'
          >
            <BtnIcon src={saveIcon} />
          </Button>
        </FlexWrap>
      </Header>
      <SortingWrapper>
        <Filter>
          <DateSortingFilter />
          <TableColumnFilter
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </Filter>
        <SearchBar setKeyword={setKeyword} />
      </SortingWrapper>
      {isLoading ? (
        <LoadingView height='80vh' />
      ) : (
        <>
          <TableHeader
            titleList={
              isAutoPayment
                ? RefundGraphHeader
                : showKioskPrice
                ? RefundGraphFullPaymentHeaderWithKiosk
                : RefundGraphFullPaymentHeader
            }
            selectedFilters={selectedFilters}
          />
          {data && data.content.length > 0 ? (
            <List>
              {data.content.map(
                (refund: IRefundDetailContent, index: number) => (
                  <RefundDetailItem
                    key={index}
                    refund={refund}
                    index={index}
                    currentPage={data.number}
                    refetch={refetch}
                    showKioskPrice={showKioskPrice}
                    selectedFilters={selectedFilters}
                  />
                ),
              )}
            </List>
          ) : (
            <RefundNotContainer>
              <Image src={refundNotImage} />
              <Text>환급 내역이 없습니다.</Text>
            </RefundNotContainer>
          )}
        </>
      )}

      <Paging
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPage={totalPage}
      />
    </FormProvider>
  );
}

export default RefundDetailList;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const List = styled.ul`
  margin-bottom: 30px;
`;

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[50]};
`;
const BtnIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const SortingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const Filter = styled.div`
  display: flex;
  gap: 24px;
`;
