import { saveChartNumber } from '@/api/user';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { Icon, Input, Modal } from '@successmode-ktp/kui';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
  onSuccess?: () => void;
  selectedChartNumber?: string;
  selectedTouristIndex?: number;
  isEditVer?: boolean;
};

function ChartNumberModal({
  onClose,
  onSuccess,
  selectedChartNumber,
  selectedTouristIndex,
  isEditVer = false,
}: Props) {
  const { watch, setValue, setFocus } = useFormContext();
  const { isKioskStore } = useAppSelector((state) => state.user);
  const { touristIndex } = useAppSelector((state) => state.user);

  const [chartNumber, setChartNumber] = useState('');
  const [touristId, setTouristId] = useState(-1);
  const [error, setError] = useState('');
  const [isFocus, setIsFocus] = useState(true);

  const { mutate: mutateSave } = useMutation(saveChartNumber);

  const onSave = () => {
    const payload = {
      touristId: touristId,
      chartNumber: chartNumber || null,
    };
    mutateSave(payload, {
      onSuccess: () => {
        setValue('chartNumber', chartNumber);
        onSuccess && onSuccess();
        handleClose();
      },
      onError: (error: any) => {
        const { code } = error.response?.data;
        if (code === 'T0001') {
          alert('고객 정보가 존재하지 않습니다.');
        }
        if (code === 'T0007') {
          setError(
            '이미 존재하는 차트번호입니다. 다른 차트번호를 입력해주세요.',
          );
        }
      },
    });
  };

  const onChangeChartNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const removedBlankValue = e.target.value.replaceAll(' ', '').trim();
    setChartNumber(removedBlankValue);
    setError('');
  };

  const enteredChartNumber = watch('chartNumber');
  const isEdit = isEditVer || !!(enteredChartNumber || selectedChartNumber);

  useEffect(() => {
    if (enteredChartNumber || selectedChartNumber) {
      setChartNumber(enteredChartNumber || selectedChartNumber);
    }
  }, [enteredChartNumber, selectedChartNumber]);

  useEffect(() => {
    if (selectedTouristIndex || touristIndex) {
      setTouristId(Number(selectedTouristIndex) || Number(touristIndex));
    }
  }, [touristIndex, selectedTouristIndex]);

  useEffect(() => {
    setIsFocus(true);
  }, []);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      onSave();
    }
  };

  const handleClose = () => {
    onClose();
    if (isKioskStore) {
      setFocus('totalPrice');
    }
  };

  return (
    <BackDrop>
      <Popover>
        <Modal.Header>
          <Title>
            <Icon.Document size={32} />
            고객 차트번호 {isEdit ? '수정' : '입력'}
          </Title>
        </Modal.Header>
        <Content>
          <Description>
            병원에서 관리하는 고객의 차트번호를 입력해주세요.
          </Description>
          <Input
            id='chartNumber'
            value={chartNumber}
            onChange={onChangeChartNumber}
            placeholder='차트번호를 입력해주세요.'
            size='lg'
            error={error}
            autoFocus={isFocus}
            onKeyDown={onKeyDown}
          />
        </Content>
        <Modal.Footer>
          <Modal.Button variant='cancel' onClick={handleClose}>
            {isEdit ? '닫기' : '차트번호 없이 진행'}
          </Modal.Button>
          <Modal.Button
            variant='ok'
            onClick={onSave}
            disabled={isEdit ? false : !chartNumber}
          >
            {isEdit ? '수정하기' : '추가하기'}
          </Modal.Button>
        </Modal.Footer>
      </Popover>
    </BackDrop>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 40;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Content = styled.div`
  width: 404px;
`;

const Description = styled.p`
  color: #5f6165;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Popover = styled.aside`
  position: fixed;
  background-color: var(--Gray-14);
  z-index: 50;
  padding: 32px;
  border-radius: 16px;
  max-height: '90vh';
  overflow-y: 'auto';
`;

const DuplicateNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 12px 16px;
  background-color: #f4f4f4;
  border-radius: 8px;
`;

export default ChartNumberModal;
