import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { setDirectPaymnet } from '@/store/modules/refund';
import { getComma, removeComma } from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useForm, useFormContext } from 'react-hook-form';
import Typography from '../common/Typography';
import FlexWrap from '../common/FlexWrap';
import LoadingView from '../common/LoadingView';
import PortePaymentList from './porte/PortePaymentList';
import { DIRECT_PRICE, isCustom } from '@/constants/common';

type Props = {
  isPayment: boolean;
  setLeafletModalInfo: Dispatch<
    SetStateAction<{
      active: boolean;
      isOver600: boolean;
      isKioskError: boolean;
    }>
  >;
  errorMessage: {
    isSubmit: boolean;
    message: string;
  };
  onClickSaveBtn: () => void;
  onClickRefundBtn: any;
  isLoading: boolean;
  isPassportCheck: boolean;
  isFetchedStorageData: boolean;
  supplyList: {
    productType: string;
    price: string;
    supplyPrice: string;
    vatPrice: string;
    isCheck: boolean;
  }[];
  isCustomKor: boolean;
};
function AmountDetail({
  isCustomKor,
  setLeafletModalInfo,
  errorMessage,
  onClickSaveBtn,
  onClickRefundBtn,
  isLoading,
  isPassportCheck,
  isFetchedStorageData,
  supplyList,
}: Props) {
  const [totalPayPrice, setTotalPayPrice] = useState(0);
  const [paymentCard, setPaymentCard] = useState('0');
  const [paymentCash, setPaymentCash] = useState('0');
  const [refundCorp, setRefundCorp] = useState('유치기관');
  const isViewModalRef = useRef(false);
  const { isAutoPayment, isKioskStore, hospitalType, hospitalId } =
    useAppSelector((state) => state.user);
  const { isDirectPayment, isDirectPassport } = useAppSelector(
    (state) => state.refund,
  );
  let paymentCardRef = useRef<string | number>(0);
  let paymentCashRef = useRef<string | number>(0);
  const methods = useFormContext();
  const { register, setValue } = useForm();
  const { temporaryStorage } = useAppSelector((state) => state.refund);
  const { isKioskRefundModal } = useAppSelector((state) => state.validate);
  const dispatch = useAppDispatch();

  // 임시저장
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      let newTotalPayPrice = 0;
      temporaryStorage.supply.forEach((item: any) => {
        newTotalPayPrice += Number(String(item.price).replace(/(,)/g, ''));
      });
      setTotalPayPrice(newTotalPayPrice);
      setPaymentCash(temporaryStorage.paymentCash || '0');
      setPaymentCard(temporaryStorage.paymentCard || '0');
      setValue('paymentCash', temporaryStorage.paymentCash || '0');
      setValue('paymentCard', temporaryStorage.paymentCard || '0');
    }
  }, [temporaryStorage]);

  const supplyData = [...methods.getValues('supply')];

  // 금액 계산
  useEffect(() => {
    let newTotalPayPrice = 0;
    methods.getValues('supply').forEach((item: any) => {
      newTotalPayPrice += Number(String(item.price).replace(/(,)/g, ''));
    });
    setTotalPayPrice(newTotalPayPrice);
    if (newTotalPayPrice <= DIRECT_PRICE) {
      isViewModalRef.current = false;
    }

    if (
      (!isAutoPayment && !isKioskStore) ||
      isDirectPassport ||
      isViewModalRef.current
    ) {
      return;
    }

    if (newTotalPayPrice > DIRECT_PRICE) {
      dispatch(setDirectPaymnet(true));
      setLeafletModalInfo({
        active: true,
        isOver600: true,
        isKioskError: false,
      });
      isViewModalRef.current = true;
    } else {
      isDirectPayment && dispatch(setDirectPaymnet(false));
    }
  }, [supplyData, isDirectPayment]);

  const paymentTypeCheck = () => {
    return isAutoPayment && !isDirectPassport && !isDirectPayment
      ? totalPayPrice - getRefundAmount(totalPayPrice)
      : totalPayPrice;
  };

  useEffect(() => {
    // 초기화 현금
    if (totalPayPrice <= 0) {
      setPaymentCash('0');
      setPaymentCard('0');
      paymentCashRef.current = '0';
      paymentCardRef.current = '0';
      methods.setValue('paymentCash', paymentCashRef.current);
      methods.setValue('paymentCard', paymentCardRef.current);
      return;
    }

    const newCardValue = getComma(
      paymentTypeCheck() - +paymentCash.replace(/(,)/g, ''),
    );

    // 카드금액이 -가 됬을때
    if (+newCardValue.replace(/(,)/g, '') < 0) {
      let cashValue =
        isAutoPayment && !isDirectPassport && !isDirectPayment
          ? totalPayPrice - getRefundAmount(totalPayPrice)
          : totalPayPrice;
      setPaymentCard('0');
      setPaymentCash(getComma(cashValue));
      paymentCardRef.current = '0';
      paymentCashRef.current = getComma(cashValue);
      methods.setValue('paymentCash', paymentCashRef.current);
      methods.setValue('paymentCard', paymentCardRef.current);
      return;
    }

    setPaymentCard(newCardValue);
    paymentCardRef.current = newCardValue;

    if (methods) {
      methods.setValue('paymentCard', paymentCardRef.current);
    }
  }, [totalPayPrice, isDirectPayment]);

  const onChangePrice = (e: any) => {
    // 금액이 0으로 시작하는거 방지
    const newtarget =
      e.target.value.charAt(0) === '0'
        ? e.target.value.substring(1)
        : e.target.value;

    let cashValue = newtarget.replace(/[^0-9]/g, '').replace(/(,)/g, '');
    let cardValue = +paymentTypeCheck() - +cashValue;

    const totalValue =
      isAutoPayment && !isDirectPassport && !isDirectPayment
        ? totalPayPrice - getRefundAmount(totalPayPrice)
        : totalPayPrice;
    if (cashValue >= totalValue) {
      cashValue =
        isAutoPayment && !isDirectPassport && !isDirectPayment
          ? totalPayPrice - getRefundAmount(totalPayPrice)
          : totalPayPrice;
      cardValue = 0;
    }

    setPaymentCard(getComma(cardValue));
    setPaymentCash(getComma(cashValue));

    paymentCardRef.current = getComma(cardValue);
    paymentCashRef.current = getComma(cashValue);

    methods.setValue('paymentCard', paymentCardRef.current);
    methods.setValue('paymentCash', paymentCashRef.current);
  };

  const onChangeRefundCorp = (way: string) => {
    methods.setValue('refundCorp', way);
    setRefundCorp(way);
  };

  const isDisabledRefundButton = useMemo(() => {
    const has0price =
      totalPayPrice === 0 ||
      removeComma(paymentCard) + removeComma(paymentCash) === 0;
    return !isPassportCheck || isLoading || has0price;
  }, [isPassportCheck, isLoading, totalPayPrice, paymentCard, paymentCash]);

  useEffect(() => {
    setPaymentCard(methods.getValues('paymentCard'));
    setPaymentCash(methods.getValues('paymentCash'));
  }, [isKioskRefundModal]);

  return (
    <>
      {hospitalType === 'EXTRA_HOSTING_CORP' && (
        <RefundMethodContainer gap='16px'>
          <RefundMethodTitle>
            <Typography size='16px' fontWeight='500' lineHeight='24px'>
              고객 유형
            </Typography>
          </RefundMethodTitle>
          <RefundMethod onClick={() => onChangeRefundCorp('유치기관')}>
            <StyledRadio
              type='radio'
              value='유치기관'
              checked={refundCorp === '유치기관'}
            />
            <Typography size='16px' lineHeight='24px' fontWeight='400'>
              유치기관
            </Typography>
          </RefundMethod>
          <RefundMethod onClick={() => onChangeRefundCorp('개인')}>
            <StyledRadio
              type='radio'
              value='KTP'
              checked={refundCorp === '개인'}
            />
            <Typography size='16px' lineHeight='24px' fontWeight='400'>
              개인
            </Typography>
          </RefundMethod>
        </RefundMethodContainer>
      )}
      {/* 커스텀 의원이고 600 만원을 초과하는 경우 */}
      {isCustom(hospitalId) && totalPayPrice > 6000000 && !isCustomKor ? (
        <PortePaymentList
          supplyList={supplyList}
          totalPayment={totalPayPrice}
        />
      ) : (
        <PaymentContainer isFullPayment={!isAutoPayment}>
          <Payment isFullPayment>
            <PaymentMethod>총 시술 금액</PaymentMethod>
            <PaymentPrice
              readOnly
              type='text'
              value={getComma(totalPayPrice)}
            />
            <UnitText>원</UnitText>
          </Payment>
          <Payment isFullPayment>
            <PaymentMethod>환급액</PaymentMethod>
            <PaymentPrice
              readOnly
              type='text'
              value={getComma(getRefundAmount(totalPayPrice))}
            />
            <UnitText>원</UnitText>
          </Payment>
          <Payment isFullPayment>
            <PaymentMethod>카드 결제</PaymentMethod>
            <PaymentPrice
              readOnly
              {...register('paymentCard')}
              type='text'
              value={paymentCard}
            />
            <UnitText>원</UnitText>
          </Payment>
          <Payment>
            <PaymentMethod>현금 결제</PaymentMethod>
            <PaymentPrice
              {...register('paymentCash')}
              type='text'
              onChange={onChangePrice}
              value={paymentCash}
              placeholder='현금 결제금액 입력'
              disabled={Boolean(!totalPayPrice)}
            />
            <UnitText>원</UnitText>
          </Payment>
        </PaymentContainer>
      )}
      {/* 커스텀 의원 */}

      <FinalSection dir='column' gap='16px'>
        <FinalWrapper justifyContent='space-between'>
          <FlexWrap>
            <Label>최종 결제 금액</Label>

            {/* 커스텀 의원 + 한국인 예외처리 */}
            {isCustom(hospitalId) && !isCustomKor ? (
              <FinalPaymentPrice>
                <FinalPriceValue
                  isColorRed={isDirectPayment && Boolean(isAutoPayment)}
                >
                  {getComma(totalPayPrice - getRefundAmount(totalPayPrice))}
                </FinalPriceValue>
                <FinalPriceUnit>원</FinalPriceUnit>
              </FinalPaymentPrice>
            ) : (
              <FinalPaymentPrice>
                <FinalPriceValue
                  isColorRed={isDirectPayment && Boolean(isAutoPayment)}
                >
                  {isAutoPayment && !isDirectPayment && !isDirectPassport
                    ? getComma(totalPayPrice - getRefundAmount(totalPayPrice))
                    : getComma(totalPayPrice)}
                </FinalPriceValue>
                <FinalPriceUnit>원</FinalPriceUnit>
              </FinalPaymentPrice>
            )}
            {/* 커스텀 의원 */}
          </FlexWrap>
          <FlexWrap alignItems='center' gap='15px'>
            {Boolean(errorMessage.message) && (
              <ErrorMessage>{errorMessage.message}</ErrorMessage>
            )}
            <Button temporaryStorage onClick={onClickSaveBtn}>
              임시저장
            </Button>
            <Button
              isValidate={!isDisabledRefundButton}
              disabled={isDisabledRefundButton}
              onClick={onClickRefundBtn}
            >
              {isLoading ? <LoadingView /> : '확인'}
            </Button>
          </FlexWrap>
        </FinalWrapper>
        {isFetchedStorageData && !isPassportCheck && (
          <AlertText>
            <Red>[여권 확인] 버튼을 먼저 눌러주세요</Red>
          </AlertText>
        )}
        {/* 커스텀 의원이 아닌 경우 */}
        {!isCustom(hospitalId) && isAutoPayment && isDirectPayment && (
          <AlertText>
            시술 <Red>금액 전체를 결제</Red>해주세요. 결제가 끝나면
            <Red> 환급 안내서를 환자에게 전달</Red>해주세요.
          </AlertText>
        )}
        {/* {hospitalId === 70 && (
					<AlertText>
						금액 수정 시 <Red>정보 수정</Red> - <Red> 여권 확인</Red>을 통해
						금액을 재입력 해주세요.
					</AlertText>
				)} */}
      </FinalSection>
    </>
  );
}

const PaymentContainer = styled.div<{ isFullPayment?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 24px 32px;
  }
`;

export const Payment = styled.div<{ isFullPayment?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const PaymentMethod = styled.p`
  padding: 12px 16px;
  font-size: ${(props) => props.theme.fontSize.xSmall};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.xSmall};
  color: ${(props) => props.theme.mono[80]};
`;

export const PaymentPrice = styled.input<{ readOnly?: boolean }>`
  border: ${(props) =>
    props.readOnly ? 'none' : `1px solid ${props.theme.mono[20]}`};
  background-color: ${(props) =>
    props.readOnly ? props.theme.mono[5] : '#ffffff'};
  flex: 0.8;
  border-radius: 4px;
  padding: 16px 32px 16px 16px;
  text-align: right;
  ::placeholder {
    color: ${(props) => props.theme.mono[50]};
  }
  font-size: ${(props) => props.theme.fontSize.xSmall};
  min-width: 120px;
  max-width: 212px;
  @media ${(props) => props.theme.mediumView} {
    flex: 1;
  }
`;

export const UnitText = styled.span`
  transform: translateX(-24px);
  font-size: 12px;
  color: ${(props) => props.theme.mono[35]};
`;
const RefundMethodContainer = styled(FlexWrap)`
  align-items: center;
  border: 1px solid #cbccce;
  background-color: #fff;
`;
const RefundMethod = styled(FlexWrap)`
  cursor: pointer;
  gap: 8px;
`;
const RefundMethodTitle = styled(FlexWrap)`
  height: 100%;
  align-items: center;
  padding: 12px 100px;
  background-color: #d7dfe4;
`;
const StyledRadio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #cbccce;
  cursor: pointer;
  &::disabled {
    cursor: default;
  }
  &:checked {
    border: 4px solid #354eff;
    background-color: #ffffff;
  }
`;

const FinalSection = styled(FlexWrap)`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  justify-content: space-between;
  @media ${(props) => props.theme.largeView} {
    padding: 24px 32px;
  }
`;
const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 50px;
  font-weight: 600;
  color: ${(props) => props.theme.mono[80]};
`;
const FinalPaymentPrice = styled(FlexWrap)`
  align-items: center;
  border: 1.5px solid ${(props) => props.theme.mono[20]};
  border-radius: 4px;
  gap: 4px;
  padding: 8px 16px;
  margin-left: 30px;
  width: 324px;
  justify-content: end;
  @media ${(props) => props.theme.largeView} {
    width: 548px;
  }
`;
const FinalWrapper = styled(FlexWrap)`
  width: 100%;
`;
const FinalPriceValue = styled.p<{ isColorRed: boolean }>`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: 150%;
  font-weight: bold;
  color: ${(props) => (props.isColorRed ? '#FF2D55' : props.theme.mono[80])};
`;
const FinalPriceUnit = styled.span`
  font-size: ${(props) => props.theme.fontSize.xSmall};
  line-height: 150%;
  color: ${(props) => props.theme.mono[50]};
`;
const Red = styled.span`
  color: red;
`;
const AlertText = styled.p`
  color: ${(props) => props.theme.mono[80]};
  font-size: ${(props) => props.theme.fontSize.regular};
  margin-left: auto;
  font-weight: 500;
`;

const Button = styled.button<{
  isValidate?: boolean;
  temporaryStorage?: boolean;
}>`
  width: ${(props) => (props.temporaryStorage ? '200px' : '240px')};
  height: 52px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.temporaryStorage ? props.theme.mono[80] : '#ffffff'};
  background-color: ${(props) =>
    props.temporaryStorage
      ? props.theme.mono[10]
      : props.isValidate
      ? props.theme.blue[100]
      : props.theme.blue[20]};
  :hover {
    opacity: 0.7;
  }
`;
const ErrorMessage = styled.p`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.priamryColors.error};
`;
export default AmountDetail;
