import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Button as ButtonComponent } from '@successmode-ktp/kui';

import { refundCancle } from '@/api/refund';
import DeleteIcon from '@/assets/refund/bin.png';
import Button from '@/components/common/Button';

import { IModal } from '@/components/home/RecentRefund';
import CustomModal from '@/components/common/CustomModal';
import FlexWrap from '@/components/common/FlexWrap';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { IRefundCanclePayload, IRefundDetailContent } from '@/types/refund';
import { getComma } from '@/util/format';
import { refundStatusDivision } from '@/util/common';
import DetailModal from '@/components/refundDetail/DetailModal';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import ChartNumberModal from '../refund/ChartNumberModal';
import { useFormContext } from 'react-hook-form';

interface IProps {
  refund: IRefundDetailContent;
  index: number;
  currentPage: number;
  refetch: any;
  showKioskPrice: boolean;
  isShowGender?: boolean;
  selectedFilters?: string[];
}

function RefundDetailItem({
  refund,
  index,
  currentPage,
  refetch,
  showKioskPrice = false,
  selectedFilters,
}: IProps) {
  const {
    hospitalName,
    nation,
    paymentType,
    refund: refundPrice,
    refundIndex,
    supplyDate,
    totalPrice,
    touristName,
    canCancel,
    isTransfer,
    extraRefund,
    refundHistoryStatus,
    gender,
    chartNumber,
    touristId,
  } = refund;
  const { globalStatus } = useGetGlobalStatus();
  const { isKioskStore } = useAppSelector((state) => state.user);
  const { setValue } = useFormContext();
  const [isModal, setIsModal] = useState<any>({ isActive: false });
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState<IModal>({
    isActive: false,
  });
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isOpenChartModal, setIsOpenChartModal] = useState<{
    isOpen: boolean;
    isEdit?: boolean;
  }>({
    isOpen: false,
    isEdit: false,
  });
  const refundCancleMutation = useMutation<
    null,
    AxiosError,
    IRefundCanclePayload
  >('refundCancle', (payload) => refundCancle(payload), {
    retry: false,
    onSuccess: () => {
      setIsDisabledButton(false);
      refetch();
      onToggleDeleteModal();
    },
    onError: (error) => {
      setIsDisabledButton(false);
      console.log('에러', error);
    },
  });

  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);
  const onToggleDeleteModal = () => {
    setIsDeleteModal((prev) => !prev);
  };

  const onClickRefundCancle = () => {
    setIsDisabledButton(true);
    refundCancleMutation.mutate({ cancelList: [refundIndex] });
  };

  const queryClient = useQueryClient();
  const onSuccessChartNumber = () => {
    queryClient.invalidateQueries('refundDetail');
  };

  const onCloseChartNumber = () => {
    setIsOpenChartModal({ isOpen: false, isEdit: false });
    setValue('chartNumber', '');
  };

  const chartRef = useRef<HTMLDivElement | null>(null);

  const updateChartBasedOnWidth = () => {
    if (chartRef.current) {
      const chartNumberHeader = document.querySelector('.chartNumberHeader');
      if (chartNumberHeader) {
        const maxLength = chartNumberHeader.clientWidth - 12;
        chartRef.current.style.width = maxLength + 'px';
      }
    }
  };

  const isShowColumn = (title: string) =>
    !selectedFilters || selectedFilters.includes(title);

  useEffect(() => {
    const handleResize = () => {
      updateChartBasedOnWidth();
    };

    updateChartBasedOnWidth();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartNumber, selectedFilters]);

  return (
    <>
      <Item>
        <Content active={refundHistoryStatus !== 'CANCEL'} flex={0.7}>
          {index + 1}
        </Content>
        {globalStatus?.isChartNumberEnabled && isShowColumn('차트번호') && (
          <div style={{ flex: 1, display: 'flex', padding: '12px 6px' }}>
            <ChartNumberSection ref={chartRef}>
              {!!chartNumber ? (
                <ChartNumberButton
                  onClick={() =>
                    setIsOpenChartModal({ isOpen: true, isEdit: true })
                  }
                >
                  {chartNumber}
                </ChartNumberButton>
              ) : (
                <ButtonComponent
                  theme='mono'
                  onClick={() => setIsOpenChartModal({ isOpen: true })}
                  size='sm'
                  style={{ width: '36px' }}
                >
                  추가
                </ButtonComponent>
              )}
            </ChartNumberSection>
          </div>
        )}
        {hospitals.length > 0 && (
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={1.2}>
            {hospitalName}
          </Content>
        )}
        <Content active={refundHistoryStatus !== 'CANCEL'} flex={1.8}>
          {refundStatusDivision(refundHistoryStatus)}
        </Content>
        {isShowColumn('시술일자') && (
          <Content
            active={refundHistoryStatus !== 'CANCEL'}
            flex={2}
            style={{ whiteSpace: 'nowrap' }}
          >
            {supplyDate}
          </Content>
        )}
        {isShowColumn('국적') && (
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={1.5}>
            {nation}
          </Content>
        )}
        {isShowColumn('고객 성함') && (
          <Content
            active={refundHistoryStatus !== 'CANCEL'}
            flex={3.5}
            style={{
              wordBreak: 'break-all',
            }}
          >
            {touristName}
          </Content>
        )}
        {isShowColumn('성별') && (
          <Content active={refundHistoryStatus !== 'CANCEL'} flex={0.5}>
            {gender === 'F' ? '여' : '남'}
          </Content>
        )}
        {isAutoPayment ? (
          <>
            {isShowColumn('의료금액') && (
              <Content active={refundHistoryStatus !== 'CANCEL'} flex={3}>
                {getComma(totalPrice)} 원
              </Content>
            )}
            {isShowColumn('환급액') && (
              <Content active={refundHistoryStatus !== 'CANCEL'} flex={2}>
                {getComma(refundPrice)} 원
              </Content>
            )}
            {isShowColumn('최종 결제금액') && (
              <Content active={refundHistoryStatus !== 'CANCEL'} flex={3}>
                {getComma(
                  paymentType === 'ORIGINAL'
                    ? +totalPrice - +refundPrice
                    : +totalPrice,
                )}
                원
              </Content>
            )}
          </>
        ) : (
          <>
            {isShowColumn('결제금액') && (
              <Content active={refundHistoryStatus !== 'CANCEL'} flex={3}>
                {getComma(totalPrice)} 원
              </Content>
            )}
            {isShowColumn('예상 환급액') && (
              <Content active={refundHistoryStatus !== 'CANCEL'} flex={2}>
                {getComma(refundPrice)} 원
              </Content>
            )}
            {isShowColumn('키오스크 환급액') &&
              isKioskStore &&
              showKioskPrice && (
                <Content
                  active={refundHistoryStatus !== 'CANCEL'}
                  flex={3}
                  isKioskStore
                >
                  {getComma(extraRefund)} 원
                </Content>
              )}
          </>
        )}
        <Content flex={2.2}>
          <Button
            text='보기'
            padding='5px 10px'
            color='#246CF6'
            borderRadius='4px'
            fontWeight
            onClick={() => setDetailModal({ data: refund, isActive: true })}
          />
        </Content>
        <Content flex={1.5}>
          {refundHistoryStatus === 'CANCEL' ||
          isTransfer ||
          (canCancel && !canCancel) ? (
            <></>
          ) : (
            <DeleteBtn onClick={onToggleDeleteModal}>
              <DeleteImg src={DeleteIcon} />
            </DeleteBtn>
          )}
        </Content>
        {isModal.isActive && (
          <DetailModal refund={refund} setIsModal={setIsModal} />
        )}
        {detailModal.isActive && (
          <DetailModal
            refund={detailModal.data}
            setUploadModal={setDetailModal}
          />
        )}
        {isDeleteModal && (
          <CustomModal
            title='환급 취소'
            desc='환급을 취소하시겠습니까?'
            btnTitle='환급 취소'
            subBtnTitle='닫기'
            btnDisabled={isDisabledButton}
            onClickSubBtn={onToggleDeleteModal}
            onClickBtn={onClickRefundCancle}
            contentElement={
              <ContentContainer dir='column'>
                시술일자 {supplyDate}
                <br />
                국적 {nation}
                <br />
                결제 금액{' '}
                {isAutoPayment
                  ? getComma(
                      paymentType === 'ORIGINAL'
                        ? +totalPrice - +refundPrice
                        : +totalPrice,
                    )
                  : getComma(totalPrice)}
                원
                <br />
                환급액 {getComma(refundPrice)}원
              </ContentContainer>
            }
          />
        )}
      </Item>
      {isOpenChartModal.isOpen && (
        <ChartNumberModal
          onClose={onCloseChartNumber}
          onSuccess={onSuccessChartNumber}
          selectedChartNumber={chartNumber || ''}
          selectedTouristIndex={touristId}
          isEditVer={isOpenChartModal.isEdit}
        />
      )}
    </>
  );
}

const Item = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  background-color: ${(props) => props.theme.pointColors.white};
  :hover {
    background-color: #e9f0fe;
  }
`;

const Content = styled.div<{
  flex: number;
  active?: boolean;
  isKioskStore?: boolean;
}>`
  color: ${(props) =>
    props.isKioskStore && props.active
      ? props.theme.blue[200]
      : props.active
      ? props.theme.mono[80]
      : props.theme.mono[35]};
  position: relative;
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  @media ${(props) => props.theme.mediumView} {
    padding: 12px 20px;
  }
`;
const DeleteImg = styled.img`
  width: 18px;
  height: 18px;
`;
const DeleteBtn = styled.button``;
const ContentContainer = styled(FlexWrap)`
  background-color: ${(props) => props.theme.mono[5]};
  border-radius: 4px;
  padding: 12px;
  color: ${(props) => props.theme.mono[65]};
`;
const ChartNumberSection = styled.div`
  flex: 1.8;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ChartNumberButton = styled.button`
  padding: 5px 0;
  color: #246cf6;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export default RefundDetailItem;
