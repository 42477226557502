import React from 'react';
import styled from 'styled-components';

import checkIcon from '@/assets/common/CheckBold.png';
import checkGrayIcon from '@/assets/common/CheckBoldGray.png';
interface IProps {
  id?: string;
  label?: string;
  isDisable?: boolean;
  isCheck: boolean;
  onClick: () => void;
  size?: 'md' | 'lg';
  wrapperStyle?: React.CSSProperties;
  highlight?: boolean;
}

function Checkbox({
  id,
  label,
  isDisable,
  isCheck = false,
  onClick,
  size = 'md',
  wrapperStyle,
  highlight = false,
}: IProps) {
  return (
    <CheckBoxWrapper id={id} onClick={onClick} style={wrapperStyle}>
      <CheckBox isCheck={isCheck} disabled={isDisable}>
        {!isDisable && (
          <CheckBoxImage src={isCheck ? checkIcon : checkGrayIcon} />
        )}
      </CheckBox>
      {label && (
        <Label htmlFor={id} size={size} highlight={highlight}>
          {label}
        </Label>
      )}
    </CheckBoxWrapper>
  );
}

const CheckBoxWrapper = styled.div<{ style?: React.CSSProperties }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  ${({ style }) => style && { ...style }};
`;

const CheckBox = styled.button<{ isCheck: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px;
  border: ${(props) =>
    props.isCheck ? 'none' : `1px solid ${props.theme.grayColors.scale4}`};
  background-color: ${(props) =>
    props.isCheck ? '#246CF6' : props.theme.pointColors.white};
  :disabled {
    cursor: auto;
    background-color: ${(props) => props.theme.grayColors.scale4};
  }
`;

const CheckBoxImage = styled.img`
  width: 12px;
  height: 8px;
  text-align: center;
`;

const Label = styled.label<{
  size?: 'md' | 'lg';
  highlight?: boolean;
}>`
  font-size: ${({ size = 'md' }) => (size === 'md' ? '14px' : '16px')};
  font-weight: ${({ highlight }) => (highlight ? '700' : '500')};
  color: ${({ highlight }) => (highlight ? '#246CF6' : '#5f6165')};
  line-height: 19px;
`;

export default Checkbox;
