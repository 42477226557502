import { Icon, Popover } from '@successmode-ktp/kui';
import { useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Checkbox from '../common/CheckBox';
import { useAppSelector } from '@/hooks/useReduxHooks';
import {
  RefundGraphFullPaymentHeader,
  RefundGraphFullPaymentHeaderWithKiosk,
  RefundGraphHeader,
} from '@/constants/refundGraph';
import ICON_FILTER from '@/assets/refund/icon_sort.png';
import ICON_FILTER_ACTIVE from '@/assets/refund/icon_sort_active.png';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

type Props = {
  selectedFilters: string[];
  setSelectedFilters: (value: string[]) => void;
};

function TableColumnFilter({ selectedFilters, setSelectedFilters }: Props) {
  const { globalStatus } = useGetGlobalStatus();
  const { isAutoPayment } = useAppSelector((state) => state.user);
  const columnFilterRef = useRef<HTMLDivElement>(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const showKioskPrice = useMemo(() => {
    return localStorage.getItem('name') === '엔젤미의원';
  }, []);

  const columnsList = (
    globalStatus?.isChartNumberEnabled ? [{ title: '차트번호' }] : []
  )
    .concat(
      isAutoPayment
        ? RefundGraphHeader
        : showKioskPrice
        ? RefundGraphFullPaymentHeaderWithKiosk
        : RefundGraphFullPaymentHeader,
    )
    .map(({ title }) => title)
    .filter(
      (title) =>
        !['No.', '구분', '환급상태', '환급 서류', '취소'].includes(title),
    );

  const onSelectAll = () => {
    const newSelectedFilters =
      selectedFilters.length === columnsList.length ? [] : columnsList;
    setSelectedFilters(newSelectedFilters as string[]);
    localStorage.setItem('showColumn', JSON.stringify(newSelectedFilters));
  };

  const onSelect = (item: string) => {
    const newSelectedFilters = selectedFilters.includes(item)
      ? selectedFilters.filter((selected) => selected !== item)
      : [...selectedFilters, item];
    setSelectedFilters(newSelectedFilters);
    localStorage.setItem('showColumn', JSON.stringify(newSelectedFilters));
  };

  const onReset = () => {
    const newSelectedFilters = columnsList.filter((item) => item !== '성별');
    setSelectedFilters(newSelectedFilters);
    localStorage.setItem('showColumn', JSON.stringify(newSelectedFilters));
  };

  const isDefaultFilter = useMemo(() => {
    const defaultFilter = columnsList.filter((item) => item !== '성별');
    return (
      selectedFilters.length === defaultFilter.length &&
      selectedFilters
        .slice()
        .sort()
        .every((v, i) => v === defaultFilter.slice().sort()[i])
    );
  }, [selectedFilters, columnsList]);

  return (
    <>
      <div ref={columnFilterRef} style={{ position: 'relative' }}>
        <FilterButton
          isOpen={isOpenFilter}
          onClick={() => setIsOpenFilter((prev) => !prev)}
          blue={!isDefaultFilter}
        >
          조회 정보
          <img
            src={isDefaultFilter ? ICON_FILTER : ICON_FILTER_ACTIVE}
            alt=''
            width={24}
          />
        </FilterButton>
      </div>
      <Popover
        isOpen={isOpenFilter}
        onClickBackdrop={() => setIsOpenFilter(false)}
        container={columnFilterRef.current}
        position='bottom-left'
        style={{
          backgroundColor: '#f4f4f4',
          top: '100%',
          left: '0',
          width: '100%',
          borderRadius: '0 0 8px 8px',
          border: '1px solid #f4f4f4',
        }}
      >
        <Checkbox
          id={'filter-all'}
          label='전체선택'
          isCheck={selectedFilters.length === columnsList.length}
          onClick={onSelectAll}
          highlight
          wrapperStyle={{
            backgroundColor: '#fafafa',
            width: '100%',
            padding: '12px 20px',
            borderBottom: '1px solid #f4f4f4',
          }}
        />
        {columnsList.map((column) => (
          <Checkbox
            id={column}
            label={column}
            isCheck={selectedFilters.includes(column)}
            onClick={() => onSelect(column)}
            wrapperStyle={{
              backgroundColor: '#fafafa',
              width: '100%',
              padding: '12px 20px',
              borderBottom: '1px solid #f4f4f4',
            }}
          />
        ))}
        <ResetButton onClick={onReset}>
          <Icon.Reload size={16} fillColor='#999' />
          필터 초기화
        </ResetButton>
      </Popover>
    </>
  );
}

const FilterButton = styled.button<{ isOpen: boolean; blue: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 148px;
  height: 52px;
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  border: 1px solid #ddd;
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom: 1px solid #f4f4f4;
    `};
  background-color: #fff;
  padding: 0 12px;
  color: ${({ blue }) => (blue ? '#246CF6' : '#999')};
  font-size: 14px;
  font-weight: 600;
`;

const ResetButton = styled.button`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #999;
  font-size: 12px;
  font-weight: 500;
`;

export default TableColumnFilter;
