import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import searchIcon from '@/assets/refund/serach.png';
import closeIcon from '@/assets/common/close_gray.png';
import Icon from '../common/Icon';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

type SearchBarProps = {
  setKeyword: Dispatch<SetStateAction<string>>;
};

function SearchBar({ setKeyword }: SearchBarProps) {
  const { register, setValue, getValues } = useForm();
  const { globalStatus } = useGetGlobalStatus();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('keyword', e.target.value.toUpperCase());
  };
  // 검색
  const applyKeyword = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.target.tagName === 'BUTTON') return;
    setKeyword(getValues('keyword'));
  };
  // 초기화
  const resetKeyword = () => {
    setValue('keyword', '');
  };

  return (
    <Container>
      <Search onSubmit={applyKeyword}>
        <Icon imgUrl={searchIcon} width='24px' height='24px' />
        <Input
          {...register('keyword')}
          type='text'
          placeholder={`(고객명 / 여권번호${
            globalStatus?.isChartNumberEnabled ? ' / 차트번호' : ''
          }로 검색)`}
          onChange={onChange}
          autoComplete='off'
        />
        <Button onClick={resetKeyword}>
          <Icon imgUrl={closeIcon} width='16px' height='16px' />
        </Button>
      </Search>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;
const Search = styled.form`
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 400px;
  height: 52px;
  border: 1.5px solid ${(props) => props.theme.mono[20]};
  border-radius: 4px;
  background-color: transparent;
`;
const Input = styled.input`
  height: 100%;
  flex: 1;
  border: none;
  background-color: transparent;
`;
const Button = styled.div`
  height: 16px;
  padding: 0;
`;

export default SearchBar;
